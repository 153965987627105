import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import ButtonNav from "../ButtonsLinks/ButtonNav";
import Button from "react-bootstrap/Button";
import AddImageButton from "../ButtonsLinks/ButtonAddImage";

function BottomNavPostUpload(props) {
  return (
    <>
      <Navbar className="bg-white border-top" fixed="bottom">
          <Nav className="mx-auto">
              <Button>Upload</Button>
          </Nav>
      </Navbar>
    </>
  );
}

export default BottomNavPostUpload;
