import { useContext, useState } from "react";
import { Context } from "../../Context";
import { API, graphqlOperation } from "aws-amplify";
import { createMessage } from "../../graphql/mutations";
import { v4 as uuidv4 } from "uuid";

function SendMessageHandler(chatId) {
  const { user } = useContext(Context);
  const [messageObject, setMessageObject] = useState({
    message: ""
  });

  function handleChange(event) {
    const { name, value } = event.target;

    setMessageObject({
      ...messageObject,
      [name]: value
    });
  }

  async function handleMessage() {
    const date = new Date();

    const input = {
      id: uuidv4(),
      authorId: user.username,
      content: messageObject.message,
      messageConversationId: chatId,
      createdAt: date,
      updatedAt: date
    };
    try {
      const createMessageResponse = await API.graphql(
        graphqlOperation(createMessage, { input })
      );
      console.log("Message sent successfully");
      console.log(createMessageResponse);
      setMessageObject({
        ...messageObject,
        message: ""
      });
    } catch (err) {
      console.log(err);
    }
  }

  return [messageObject, handleChange, handleMessage];
}

export default SendMessageHandler;
