import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

function TextContainer(props) {
  return (
    <>
      <Container className="mt-5 pt-5">
        <Row className="mb-2">
          <Col className="text-center">
            <p className="h4">{props.text}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TextContainer;
