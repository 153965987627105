import React from "react";
import { Link } from "react-router-dom";
import TopNavHome from "../components/Navs/TopNavHome";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "../css/home.css";
import GuestLoginHandler from "../handlers/AuthHandlers/GuestLoginHandler";
// import SignoutHandler from "../handlers/AuthHandlers/SignoutHandler";
// import SubmitButtonContainer from "../components/ContainersRows/ContainerSubmitButton";

function Home() {
  const [
    handleGuestLogin
  ] = GuestLoginHandler();

  // const [user, handleSignout] = SignoutHandler();

  const isDisabled = false

  return (
    <>
      <Container className="bk-dog">
        <TopNavHome />
        <Container className="">
        <Row className="pb-5">
          <Col className="text-center px-0">
            <h2 className="d-inline-block">Beaukoh
            {/* <sub><h6 className="d-inline-block font-italic">beta</h6></sub> */}
            </h2>
            <p className="font-italic text-center px-0">
              Together working to help all animals
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="pt-5 mt-5">
        <Row className="mt-5">
          <Col className="text-center">
            {isDisabled ? 
            <Link to="#">
              <Button className="w-13r" disabled>
                Log in
              </Button>
            </Link>
            :
            <Link to="/login">
              <Button className="w-13r">
                Log in
              </Button>
            </Link>
          }
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
          {isDisabled ? 
            <Link to="#">
              <Button className="w-13r" disabled>Sign up</Button>
            </Link>
            :
            <Link to="/signup">
              <Button className="w-13r">Sign up</Button>
            </Link>
}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <Button
              variant="secondary"
              className="w-13r"
              onClick={handleGuestLogin}
              disabled={isDisabled}
            >
              Explore
            </Button>
          </Col>
        </Row>
      </Container>
      {/* <SubmitButtonContainer text="Sign out" handleSubmit={handleSignout} /> */}
      </Container>
    </>
  );
}

export default Home;
