import React, { useState, useCallback, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopNavGeneric from "../components/Navs/TopNavMain";
import { Link, useParams, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Media from "react-bootstrap/Media";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-easy-crop";
import "../index.css";
import { Context } from "../Context";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  getUser as GetUser,
  getPost as GetPost,
  searchUsers as SearchUsers,
  searchCompanions as SearchCompanions,
  getCompanion as GetCompanion,
} from "../graphql/queries";
import { IoIosArrowForward } from "react-icons/io";
import {
  createRequest as CreateRequest,
  deleteRequest as DeleteRequest,
  createGuardianshipLink as CreateGuardianshipLink,
  deleteGuardianshipLink as DeleteGuardianshipLink,
} from "../graphql/mutations";
import {
  // updateUser as UpdateUser,
  searchUsersDetail as SearchUsersDetail,
  // getRequestDetail as GetRequestDetail
} from "../graphql/customQueries";

function AddGuardian() {
  let { companionId } = useParams();

  const { user } = useContext(Context);
  // const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMsg, setNoResultsMsg] = useState("");
  const staticImg =
    "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";
  const [companionRequests, setCompanionRequests] = useState([]);
  const [companion, setCompanion] = useState([]);
  const [guardians, setGuardians] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function getCompanionDetails(id) {
      try {
        const {
          data: { getCompanion },
        } = await API.graphql(graphqlOperation(GetCompanion, { id: id }));
        console.log(getCompanion);
        setCompanion(getCompanion);

        console.log("requests", getCompanion.requests.items);
        const requestsInfo = getCompanion.requests.items;

        // const guardiansInfo = getCompanion.guardians

        console.log("guardiansInfo", getCompanion.guardians);

        let guardiansObj = [];
        if (getCompanion.guardians.items.length > 0) {
          const guardiansInfo = getCompanion.guardians.items;
          guardiansObj = guardiansInfo.map((guardian) => {
            return guardian.guardianshipLinkGuardianId;
          });
          setGuardians(guardiansObj);
        }

        if (!guardiansObj.includes(user.id)) {
          history.push({
            pathname: `/guardian/${user.id}`,
            // state: {
            //   confirmationMessage: `Thanks ${newUser.username} for signing up! Check your email to verify your account. Once verified head back and log in.`
            // }
          });
        } else {
          setCompanionRequests(requestsInfo);
        }
      } catch (err) {
        console.log("error: ", err);
      }
    }

    getCompanionDetails(companionId);
  }, []);

  console.log("companionRequests", companionRequests);
  console.log("guardians", guardians);

  async function handleSearch(e) {
    const targetValue = e.target.value;
    console.log("tagetValue", targetValue);
    setSearchValue(e.target.value);

    let searchInfo = "";
    //   if (guardianHighlighted) {
    const input = {
      username: {
        matchPhrasePrefix: targetValue,
      },
    };

    const searchResponse = await API.graphql(
      graphqlOperation(SearchUsersDetail, {
        filter: input,
        limit: 5,
        sort: { direction: "desc" },
      })
    );

    searchInfo = searchResponse.data.searchUsers.items;

    console.log("searchInfo", searchInfo);

    let profPic = "";

    if (searchInfo.length > 0) {
      const searchObj = searchInfo
        .filter((info) => {
          if (info.id === user.id || guardians.includes(info.id)) {
            console.log("info", info);
            return false;
          }
          return true;
        })
        .map(async (info) => {
          // console.log("info", info);
          // console.log("info.profilePicIcon===null", info.profilePicIcon === null);

          if (info.profilePicIcon === null) {
            profPic = staticImg;
          } else {
            const key = info.profilePicIcon.key;
            profPic = await Storage.get(key);
          }
          return { ...info, profPic };
        });

      Promise.all(searchObj).then((results) => {
        //   console.log("results", results);
        setSearchResults(results);
        //   return results
      });
      if (searchObj.length === 0) {
        setNoResultsMsg("No matches found");
      }
    } else {
      setSearchResults([]);
      if (targetValue === "") {
        setNoResultsMsg("");
      } else {
        setNoResultsMsg("No matches found");
      }
    }
  }

  async function requestAddGuardian(guardianId) {
    try {
      const inputData = {
        type: "addGuardian",
        createdById: user.id,
        requestedUserId: guardianId,
        companionId: companionId,
        accepted: false,
        current: true,
      };

      const createRequestResponse = await API.graphql(
        graphqlOperation(CreateRequest, {
          input: inputData,
        })
      );

      console.log("createRequestResponse", createRequestResponse);
      //   setCompanionRequest(createRequestResponse.data.createRequest);
    } catch (err) {
      console.log("error: ", err);
    }
  }

  async function removeRequestAddGuardian(requestId) {
    try {
      console.log(requestId);
      const deleteRequestResponse = await API.graphql(
        graphqlOperation(DeleteRequest, {
          input: { id: requestId },
        })
      );

      console.log("deleteRequestResponse", deleteRequestResponse);
      //   setCompanionRequests(null);
    } catch (err) {
      console.log("error: ", err);
    }
  }

  console.log("searchResults", searchResults);

  let searchResultsComponent = searchResults.map((result) => {
    let requestObj = [];

    if (result.requests.items.length > 0) {
      const userRequests = result.requests.items;

      requestObj = userRequests
        .filter((request) => {
          if (
            request.type === "addGuardian" &&
            request.companion.id === companionId
          ) {
            return true;
          } else {
            return false;
          }
        })
        .map((request) => {
          return request;
        });

      console.log("requestObj", requestObj);
    }

    console.log("result", result);
    return (
      <Media key={result.id} className="border-bottom py-2">
        <img
          width={"40rem"}
          height={"40rem"}
          className="mr-3 rounded-circle"
          src={result.profPic}
        />
        <Media.Body>
          <Link to={`/guardian/` + result.id}>
            <Container className="px-0">
              <Row>
                <Col>
                  <h6 className="m-0 pr-4 text-dark d-inline-block">
                    {result.username}
                  </h6>
                  {requestObj.length > 0 ? (
                    <>
                      {/* <Button
                        className="d-inline-block mr-4 py-1 btn-sm"
                        disabled
                      >
                        Requested
                      </Button> */}
                      <Button
                        variant="info"
                        className="d-inline-block py-1 btn-sm"
                        onClick={() =>
                          removeRequestAddGuardian(requestObj[0].id)
                        }
                      >
                        Cancel request
                      </Button>
                    </>
                  ) : (
                    <Button
                      className="d-inline-block mr-4 py-1 btn-sm"
                      onClick={() => requestAddGuardian(result.id)}
                    >
                      Add
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </Link>
        </Media.Body>
      </Media>

      // <Media key={result.id}>
      //   <img
      //     width={"70rem"}
      //     height={"70rem"}
      //     className="mr-3 rounded-circle"
      //     src={result.profPic}
      //     //   alt="koh tao animal clinic"
      //   />
      //   <Media.Body>
      //     {/* <Link to={guardianHighlighted ? `/guardian/` + result.id : `/companion/` + result.id}> */}
      //       <Container className="px-0">
      //         <Row>
      //           <Col className="col-10">
      //             <Row>
      //               <Col>
      //                 <h6 className="d-inline text-dark">{result.username}</h6>
      //               </Col>
      //             </Row>
      //             <Row>
      //             {requestObj.length > 0 ?
      //             <>
      //               <Col>
      //               <Button disabled>Requested</Button>
      //               </Col>
      //               <Col>
      //               <Button onClick={() => removeRequestAddGuardian(requestObj[0].id)}>Cancel</Button>
      //               </Col>
      //               </>
      //               :
      //               <Col>
      //               <Button onClick={()=>requestAddGuardian(result.id)}>Add</Button>
      //               </Col>
      //               }
      //             </Row>
      //           </Col>
      //           <Col className="col-2 d-flex align-items-center">
      //             <IoIosArrowForward size="1.5em" className="text-info" />
      //           </Col>
      //         </Row>
      //       </Container>
      //     {/* </Link> */}
      //   </Media.Body>
      // </Media>
    );
  });

  return (
    <>
      <TopNavGeneric
        title="Add Guardian"
        addLeftIcon={true}
        leftIconPath={"/companion/" + companionId}
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="mt-5">
        <Row className="py-2">
          <Col className="">
            <FormControl
              className=""
              type="text"
              maxLength="100"
              placeholder="Search guardians to add..."
              name="search"
              value={searchValue}
              onChange={handleSearch}
              required
            />
          </Col>
        </Row>
        <Row className="py-2">
          <Col>
            {searchResults.length > 0 ? (
              searchResultsComponent
            ) : (
              <p>{noResultsMsg}</p>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddGuardian;
