import React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { IoMdAdd } from "react-icons/io";

function ConversationsHeaderNav(props) {
  const backgroundColor = "bg-white";
  return (
    <>
      <Navbar
        className={`${backgroundColor} px-1 py-1 border-bottom`}
        sticky="top"
      >
        <Nav className="mr-auto">
          <Button
            className="d-flex align-items-center justify-content-center rounded-circle p-2 bg-white border-white"
            disabled={true}
          >
            <IoMdAdd size="" className="text-white" />
          </Button>
        </Nav>
        <Navbar.Brand className="mx-auto">Conversations</Navbar.Brand>
        <Nav className="ml-auto">
          {/* <Link to="/start-conversation"> */}
          <Button
            className="d-flex align-items-center justify-content-center rounded-circle p-2 shadow"
            disabled={true}
          >
            <IoMdAdd size="" />
          </Button>
          {/* </Link> */}
        </Nav>
      </Navbar>
    </>
  );
}

export default ConversationsHeaderNav;
