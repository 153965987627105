import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import TopNavGeneric from "../components/Navs/TopNavMain";
import { Context } from "../Context";

function NotFoundPage() {
  const { user } = useContext(Context);

  return (
    <>
      <TopNavGeneric title="404" />
      <Container className="mt-5">
        <Row className="mb-2">
          <Col className="text-center">
            <p>Error page</p>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="text-center">
            <Link to={"/guardian/" + user.id}>
              <Button>Head back</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NotFoundPage;
