import React, { useState } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { getUser } from "../src/graphql/queries";

const Context = React.createContext();

function ContextProvider({ children }) {
// STATE
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [userCompanions, setUserCompanions] = useState([]);
  const [isGuest, setIsGuest] = useState(false);

  const infoColor = "#3B3CCE";
  const primaryColor = "#05E2D8";
  const secondaryColor = "#FF2277";


  console.log("context", user);
  // console.log('contextCompanions', userCompanions)

  const fetchSession = async () => {
    try {
      await Auth.currentSession();
      const sessionUser = await Auth.currentAuthenticatedUser();
      console.log("sessionUser", sessionUser.username);

      const userResponse = await API.graphql(
        graphqlOperation(getUser, { id: sessionUser.username })
      );
      console.log("userResponse", userResponse);

      // UNAUTHENTICATE IF USER DATA IS NULL
      if (userResponse.data.getUser === null) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
        const companionInfo = userResponse.data.getUser.companions.items;

        const companionObj = companionInfo.map((companion) => {
          return companion.guardianshipLinkCompanionId;
        });

        setUserCompanions(companionObj);
        setUser(userResponse.data.getUser);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
    console.log("setIsAuthenticating(false);");
    setIsAuthenticating(false);
  };

  function updateAuthenticatedUser(user, bool) {
    setUser(user);
    setIsAuthenticated(bool);
  }

  return (
    <Context.Provider
      value={{
        isAuthenticated,
        user,
        isAuthenticating,
        updateAuthenticatedUser,
        fetchSession,
        setUser,
        updateAuthenticatedUser,
        isGuest,
        setIsGuest,
        infoColor,
        primaryColor,
        secondaryColor,
        userCompanions,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export { ContextProvider, Context };
