import React from "react";
import Container from "react-bootstrap/Container";
import ConversationsHeaderNav from "../../components/Navs/TopNavConversations";
import BottomNavGeneric from "../../components/Navs/BottomNavMain";
import ListConversationsHandler from "../../handlers/ConversationHandlers/ListConversationsHandler";

function Conversations() {
  const [conversationElements] = ListConversationsHandler();

  return (
    <>
      <ConversationsHeaderNav />
      <Container className="mt-4 mb-5">{conversationElements}</Container>
      <BottomNavGeneric chatIconFilled={true} />
    </>
  );
}

export default Conversations;
