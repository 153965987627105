import { useContext } from "react";
import { Context } from "../../Context";
import { Auth } from "aws-amplify";

function SignoutHandler() {
  const { user, updateAuthenticatedUser } = useContext(Context);
  async function handleSignout() {
    try {
      Auth.signOut();
      updateAuthenticatedUser(null, false);
    } catch (error) {
      console.log("Error: ", error);
    }
  }

  return [user, handleSignout];
}

export default SignoutHandler;
