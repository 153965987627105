import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router} from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify'
import {ContextProvider} from './Context'
import './custom.scss';
import aws_exports from "./aws-exports"
import {IntlProvider} from "react-intl"

Amplify.configure(aws_exports)


ReactDOM.render(
	<IntlProvider locale={navigator.language}>
	<ContextProvider>
		<Router>
			<App />
		</Router>
	</ContextProvider>
	</IntlProvider>
	, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
