import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context";
import { Auth } from "aws-amplify";
import userAuthValidation from "../../hooks/userAuthValidation";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { getUser, getConversation } from "../../graphql/queries";
import {
  createUser,
  createConvoLink,
  updateConversation,
} from "../../graphql/mutations";
import config from "../../aws-exports";
import imageCompression from "browser-image-compression";
import generic_guardian_profile_blue from "../../static/generic_guardian_profile_blue.png";
import generic_guardian_profile_green from "../../static/generic_guardian_profile_green.png";
import generic_guardian_profile_orange from "../../static/generic_guardian_profile_orange.png";
import generic_guardian_profile_pink from "../../static/generic_guardian_profile_pink.png";
import generic_guardian_profile_purple from "../../static/generic_guardian_profile_purple.png";
// import uuid from "uuid/v4";
import { uuid } from "uuidv4";

function LoginHandler() {
  // STATE
  const [userLogin, setUserLogin] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
  });

  // CONTEXT
  const { updateAuthenticatedUser, setUser } = useContext(Context);

  // HOOKS - USER INPUT VALIDATION
  const [inputErrors, validation] = userAuthValidation();

  // HISTORY
  const history = useHistory();

  // S3 CONFIG
  const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket,
  } = config;

  // LIST OF GENERIC PROFILE PICTURES ON FIRST LOGIN
  const genericProfilePicList = [
    generic_guardian_profile_blue,
    generic_guardian_profile_green,
    generic_guardian_profile_orange,
    generic_guardian_profile_pink,
    generic_guardian_profile_purple,
  ];

  // useEffect(() => {
  //   let isMounted = true;
  //   handleSubmit().then((data) => {
  //     if (isMounted) {
  //       updateAuthenticatedUser(data);
        
  //     }
  //   });

  //   return () => {
  //     isMounted = false;
  //     history.push("/posts");
  //   };
  // }, []);

  // function useAsyncSubmit() {
  //   useEffect(() => {
  //     let isMounted = true;
  //     handleSubmit().then(data => {
  //       if (isMounted) updateAuthenticatedUser(data);
  //     });
  //     return () => { isMounted = false };
  //   }, [handleSubmit, updateAuthenticatedUser]);
  // }

  // async function handleImageUpload() {}

  // HANDLERS - HANDLE INPUT CHANGE
  function handleChange(event) {
    const { name, value } = event.target;

    setUserLogin({
      ...userLogin,
      [name]: value,
    });

    validation(name, value, userLogin);
  }

  // HANDLERS - HANDLE LOGIN SUBMIT
  async function handleSubmit() {
    const { username, password } = userLogin;

    try {
      // Auth sign in
      const user = await Auth.signIn({
        username,
        password,
      });

      // Check if user in database
      const getUserResponse = await API.graphql(
        graphqlOperation(getUser, { id: user.username })
      );

      // If user in database then log in
      if (getUserResponse.data.getUser) {
        console.log("username: user.username", user.username);
        const authenticatedUser = {
          username: user.username,
          id: user.attributes.sub,
          email: user.attributes.email,
          preferred_username: user.attributes.preferred_username,
        };

        console.log(
          "getUserResponse.data.getUser",
          getUserResponse.data.getUser
        );

        // return getUserResponse.data.getUser;

        updateAuthenticatedUser(getUserResponse.data.getUser, true);

        // setUser(getUserResponse.data.getUser)
        // setIsAuthenticated()
        // console.log("getUserResponse:", getUserResponse);
        history.push("/guardian/" + user.username);
      }
      // If user not in database then add user to database
      else {
        // Randomly generating profile picture from list
        const genericProfilPic =
          genericProfilePicList[
            Math.floor(Math.random() * genericProfilePicList.length)
          ];

        const mimeType = "image/jpeg";

        let blob = await fetch(genericProfilPic).then((r) => r.blob());
        var blobFile = new File([blob], "generic_profile_pic", {
          type: mimeType,
        });

        // Generic Profile Pic
        const imgOptions = {
          maxSizeMB: 0.05,
          maxWidthOrHeight: 200,
          useWebWorker: true,
        };

        //   compressing file to icon size
        const compressedFile = await imageCompression(blobFile, imgOptions);

        console.log(
          "compressedFile instanceof Blob",
          compressedFile instanceof Blob
        ); // true
        console.log(
          `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
        ); // smaller than maxSizeMB

        const imgKey = `generic_profile_pic_${uuid()}.jpeg`;

        const uploadResponseImg = await Storage.put(imgKey, compressedFile, {
          contentType: mimeType,
          // level: 'protected',
          //   customPrefix: {
          //     private:''
          //  }
        });

        console.log("uploadResponseImg", uploadResponseImg);

        // Getting link so do not need to call S3 with key
        const profilePicLink = await Storage.get(imgKey);

        const imgForUpload = {
          bucket,
          key: imgKey,
          region,
        };

        // GETS AWS IDENTITY ID WHICH WE WILL USE TO ACCESS PROTECTED FILES IN S3
        const currentCreds = await Auth.currentCredentials();
        const userIdentityId = currentCreds._identityId;

        const createUserObj = {
          id: user.username,
          user_id: user.attributes.sub,
          username: user.username,
          email: user.attributes.email,
          preferred_username: user.username,
          profilePicLink: profilePicLink,
          profilePicIcon: imgForUpload,
          profilePicAdded: false,
          bioAdded: false,
          companionAdded: false,
          postAdded: false,
          awsIdentityId: userIdentityId,
        };

        const createUserResponse = await API.graphql(
          graphqlOperation(createUser, { input: createUserObj })
        );

        console.log("createUserResponse:", createUserResponse);

        const updateAuthUser = await Auth.updateUserAttributes(user, {
          preferred_username: user.username,
        });

        console.log("updateUserResponse:", updateAuthUser);

        // return createUserObj;

        updateAuthenticatedUser(createUserObj, true);

        history.push("/guardian/" + createUserObj.id);
      }
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setError({
        isError: true,
        errorMessage: err.message,
      });
      console.log(error);
    }
  }
  return [userLogin, error, inputErrors, handleChange, handleSubmit];
}

export default LoginHandler;

// // Commenting out koh tao and feedback channels...
//         // // Adding user to Koh Tao and Feedback chats on first login
//         // // will need to change Id's if create new or different chats
//         const kohTaoId = "276e9b8a-6809-496d-af86-bdef147d8d21";
//         const feedbackId = "a512d528-69ad-4dab-bd86-032cbb30a934";

//         // get Koh Tao conversation, add member, add convoLink
//         // adding to Koh Tao Conversation
//         const { data: kohTaoData } = await API.graphql(
//           graphqlOperation(getConversation, { id: kohTaoId })
//         );
//         const { data: feedbackData } = await API.graphql(
//           graphqlOperation(getConversation, { id: feedbackId })
//         );

//         await API.graphql(
//           graphqlOperation(updateConversation, {
//             input: {
//               id: kohTaoId,
//               members: [...kohTaoData.getConversation.members, user.username]
//             }
//           })
//         );
//         await API.graphql(
//           graphqlOperation(updateConversation, {
//             input: {
//               id: feedbackId,
//               members: [...feedbackData.getConversation.members, user.username]
//             }
//           })
//         );

//         // adding to Koh Tao Conversation
//         await API.graphql(
//           graphqlOperation(createConvoLink, {
//             input: {
//               convoLinkUserId: createUserObj.username,
//               convoLinkConversationId: kohTaoId
//             }
//           })
//         );

//         // adding to Feedback Conversation
//         await API.graphql(
//           graphqlOperation(createConvoLink, {
//             input: {
//               convoLinkUserId: createUserObj.username,
//               convoLinkConversationId: feedbackId
//             }
//           })
//         );
