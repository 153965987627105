import React, { useState, useContext } from "react";
import NavTop from "../components/Navs/TopNavUpload";
import PageLoader from "../components/OtherComponents/LoaderPage";
import TextContainer from "../components/ContainersRows/ContainerText";
import NavBottom from "../components/Navs/BottomNavUpload";
// import AddCompanionInfoContainer from "../components/AddEditImages/AddCompanionInfoContainer";
import PostCaptionContainer from "../components/ContainersRows/ContainerPostCaption";
import CompanionInfoContainer from "../components/ContainersRows/ContainerCompanionInfo"
import CropImageHandler from "../handlers/ImageHandlers/CropImageHandler";
import Cropper from "react-easy-crop";
import "../css/crop.css";
import { Context } from "../Context";
import TextareaAutosize from "react-textarea-autosize";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import Media from "react-bootstrap/Media";

function AddCompanion() {
  const isProfile = false;
  const isCompanion = true;

  const { user } = useContext(Context);
  //   const isProfile = props.isProfile;
  const [widthAspect, setWidthAspect] = useState(4);
  const [heightAspect, setHeightAspect] = useState(4);
  //   const [aspect, setAspect] = useState(4 / 3);
  const [orientBtnText, setOrientBtnText] = useState("Landscape");
  const [page, setPage] = useState(1);

  const [
    handleChange,
    handleRotation,
    crop,
    setCrop,
    loading,
    zoom,
    setZoom,
    uploadFileS3,
    onCropComplete,
    imgSrc,
    rotation,
    croppedImg,
    getCroppedFile,
    captionBio, setCaptionBio,
    companionName, setCompanionName,
    hasShelter, setHasShelter,
    hasMedicalCare, setHasMedicalCare,
    hasFood, setHasFood,
    isYourCompanion, setIsYourCompanion
  ] = CropImageHandler(isProfile,isCompanion) ;

  function handleDimension() {
    if (orientBtnText === "Landscape") {
      console.log("setting to portrait");
      setWidthAspect(3);
      setHeightAspect(4);
      setOrientBtnText("Portrait");
    } else {
      console.log("setting to landscape");
      setWidthAspect(4);
      setHeightAspect(3);
      setOrientBtnText("Landscape");
    }
  }

  async function handleNext() {
    await getCroppedFile();
    setPage(page => page + 1);
  }

  function handleBack() {
    setPage(page => page - 1);
  }

  //   const title = isProfile ? "Edit profile picture" : "Create post";
  const cancelLink = isProfile ? "/edit-profile" : "/guardian/" + user.id;
  const isDisabled = imgSrc === null || page !== 1 ? true : false;
  const isAddPhotoDisabled = page !== 1 ? true : false;
  const title = "Add a companion"

  return (
    <>
      {page === 1 ? (
        <NavTop
          title={title}
          isDisabled={isDisabled}
          isLeftLink={true}
          leftText={"Cancel"}
          rightText={"Next"}
          leftLink={cancelLink}
          rightOnClick={handleNext}
        />
      ) : (
        <NavTop
          title={title}
          isDisabled={false}
          isLeftLink={false}
          leftText={"Back"}
          rightText={"Save"}
          leftOnClick={handleBack}
          rightOnClick={uploadFileS3}
        />
      )}
      <PageLoader loading={loading} />
      {!loading && imgSrc === null && <TextContainer text="Add a photo" />}
      {page === 1 ? (
        <div className="crop-container">
          <Cropper
            image={imgSrc}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={isProfile || isCompanion ? 1 : widthAspect / heightAspect}
            cropShape={isProfile || isCompanion ? "round" : "rect"}
            showGrid={true}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            restrictPosition={false}
          />
        </div>
      ) : (
        <CompanionInfoContainer
          img={croppedImg}
          width={widthAspect}
          height={heightAspect}
          imgSize={20}
          // dog={dog}
          // handleDog={handleDog}
          nameValue={companionName}
          onChangeName={setCompanionName}
          captionValue={captionBio}
          onChange={setCaptionBio}
          hasMedicalCare={hasMedicalCare}
          setHasMedicalCare={setHasMedicalCare}
          hasShelter={hasShelter}
          setHasShelter={setHasShelter}
          hasFood={hasFood}
          setHasFood={setHasFood}
          isYourCompanion={isYourCompanion}
          setIsYourCompanion={setIsYourCompanion}
        />
      )}
      {/* <NavBottom
        isDisabled={isDisabled}
        isAddPhotoDisabled={isAddPhotoDisabled}
        addPhotoBtnText={imgSrc === null ? "Add" : "Change"}
        handleChange={handleChange}
        handleRotation={handleRotation}
        handleDimension={handleDimension}
      /> */}
      <NavBottom
        isDisabled={isDisabled}
        addPhotoBtnText={imgSrc === null ? "Add" : "Change"}
        removeOrientationBtn={true}
        isAddPhotoDisabled={isAddPhotoDisabled}
        handleChange={handleChange}
        handleRotation={handleRotation}
        handleDimension={handleDimension}
      />
    </>
    // <AddEditImagesContainer isProfile={isProfile} isCompanion={isCompanion}/>
  );
}

export default AddCompanion;
