import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../Context";
import { API, graphqlOperation } from "aws-amplify";
import { getUserConversations } from "../../graphql/customQueries";
import LinkComponent from "../../components/ButtonsLinks/LinkComponent";

function ListConversationsHandler() {
  const { user } = useContext(Context);
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    userConversations();
  }, []);

  async function userConversations() {
    try {
      const getConversationsResponse = await API.graphql(
        graphqlOperation(getUserConversations, { id: user.username })
      );

      setConversations(
        getConversationsResponse.data.getUser.conversations.items
      );
    } catch (error) {
      console.log(error);
    }
  }

  const conversationElements = conversations.map(conversation => {
    const { id, name } = conversation.conversation;
    return <LinkComponent key={id} name={name} path={`/conversations/${id}`} />;
  });

  return [conversationElements];
}

export default ListConversationsHandler;
