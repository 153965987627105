import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {
  MdAddAPhoto,
} from "react-icons/md";

function AddImageButton(props) {
  return (
    <>
      <Button variant="white" className="p-1" disabled={props.isDisabled}>
        <Form.File id="formcheck-api-regular">
          <Form.File.Label className="m-0">
            <MdAddAPhoto size="1.5em" />
            <p className="m-0">
              <small>{props.text}</small>
            </p>
          </Form.File.Label>
          <Form.File.Input
            accept="image/x-png,image/gif,image/jpeg, video/*"
            onChange={props.handleChange}
            style={{ display: "none" }}
            disabled={props.isDisabled}
          />
        </Form.File>
      </Button>
    </>
  );
}

export default AddImageButton;
