import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-bootstrap/Media";
import Button from "react-bootstrap/Button";
import TopNavGeneric from "../components/Navs/TopNavMain";
import kohTaoAnimalClinic from "../static/koh_tao_animal_clinic.png";

function Emergency() {
  return (
    <>
      <TopNavGeneric
        title="Emergency"
        addLeftIcon={true}
        leftIconPath="/"
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="py-5">
        <Media className="pt-5">
          <img
            width={64}
            height={64}
            className="mr-3 rounded"
            src={kohTaoAnimalClinic}
            alt="koh tao animal clinic"
          />
          <Media.Body>
            <Container className="px-0">
              <Row>
                <Col>
                  <h6 className="d-inline">Koh Tao Animal Clinic</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <small>
                    <p className="mb-0">
                      <b className="text-muted">Address:</b>{" "}
                      <Link to="#" className="text-info">
                        Ko Tao, Ko Pha-ngan District, Surat Thani 84280,
                        Thailand
                      </Link>
                    </p>
                  </small>
                </Col>
              </Row>
              <Row>
                <Col>
                  <small>
                    <p className="mb-0">
                      <b className="text-muted"> Phone#:</b>{" "}
                      <Link to="#" className="text-info">
                        +66 81 090 5372
                      </Link>
                    </p>
                  </small>
                </Col>
              </Row>
            </Container>
          </Media.Body>
        </Media>
        <Container className="py-2">
          <Row>
            <Col className="text-center">
              <Button disabled={true} size="sm" className="h6 px-4">
                Connect with available staff
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Emergency;
