import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import userAuthValidation from "../../hooks/userAuthValidation";

function SignupHandler() {
  const history = useHistory();
  const [newUser, setNewUser] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [error, setError] = useState({
    isError: false,
    message: ""
  });
  const [inputErrors, validation] = userAuthValidation();

  function handleChange(event) {
    const { name, value } = event.target;
    setNewUser({
      ...newUser,
      [name]: value
    });
    validation(name, value, newUser);
  }

  // This is a work around to make sure only have unique emails
  async function checkUniqueEmail(email) {
    try {
      // use incorrect password (can't have password less than 6) to ensure it fails
      await Auth.signIn(email, "123");
      return true; // Pigs can fly
    } catch (error) {
      // Cognito throws a specific error when a username/email is in use but the password is wrong.
      return error.code;
    }
  }

  async function handleSubmit() {
    const { username, password, email } = newUser;

    const result = await checkUniqueEmail(email);

    // Use this specific error to check
    if (result !== "NotAuthorizedException") {
      try {
        await Auth.signUp({
          username: username,
          password: password,
          attributes: {
            email: email
          }
        });

        history.push({
          pathname: "/confirm-signup",
          state: {
            confirmationMessage: `Thanks ${newUser.username} for signing up! Check your email to verify your account. Once verified head back and log in.`
          }
        });
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setError({
          isError: true,
          message: err.message
        });
      }
    } else {
      setError({
        isError: true,
        message: "Email already attached to another user"
      });
    }
  }

  return [newUser, error, inputErrors, handleChange, handleSubmit];
}

export default SignupHandler;
