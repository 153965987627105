import { useEffect, useState, useRef, useContext } from "react";
import { onCreateMessage } from "../../graphql/subscriptions";
import { API, graphqlOperation } from "aws-amplify";
import { getConversationDetail } from "../../graphql/customQueries";
import { Context } from "../../Context";

function DisplayMessagesHandler(chatId) {
  const { user } = useContext(Context);
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);
  const [fetchDetail, setFetchDetail] = useState({
    nextToken: null,
    hasMore: true
  });

  const scrollToBottomAuto = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "auto" });
  };
  const scrollToBottomSmooth = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    getMessages();
  }, [fetchDetail]);

  useEffect(() => {
    const createMessageListener = API.graphql(
      graphqlOperation(onCreateMessage, { authorId: user.username })
    ).subscribe({
      next: messageData => {
        const newMessage = messageData.value.data.onCreateMessage;

        if (newMessage.conversation.id === chatId) {
          const prevMessages = messages.filter(
            message => message.id !== newMessage.id
          );

          console.log("listener", newMessage);

          const updatedMessages = [...prevMessages, newMessage];

          setMessages(updatedMessages);
          scrollToBottomSmooth();
        }
      }
    });
    return () => createMessageListener.unsubscribe();
    // FROM NADIR...https://github.com/dabit3/react-amplify-appsync-files-s3/blob/master/src/Users.js
    // useEffect(() => {
    //   fetchUsers()
    //   const subscription = API.graphql(graphqlOperation(onCreateUser))
    //     .subscribe({
    //       next: async userData => {
    //         const { onCreateUser } = userData.value.data
    //         dispatch({ type: 'ADD_USER', user: onCreateUser })
    //       }
    //     })
    //   return () => subscription.unsubscribe()
    // }, [])
  }, [messages]);

  async function getMessages() {
    try {
      if (!fetchDetail.hasMore) return;
      const { data } = await API.graphql(
        graphqlOperation(getConversationDetail, {
          id: chatId,
          nextToken: fetchDetail.nextToken
        })
      );

      setMessages([...messages, ...data.getConversation.messages.items]);

      setFetchDetail({
        ...fetchDetail,
        nextToken: data.getConversation.messages.nextToken,
        hasMore: data.getConversation.messages.nextToken !== null
      });

      scrollToBottomAuto();
    } catch (err) {
      console.log("error", err);
    }
  }
  return [messages, messagesEndRef];
}

export default DisplayMessagesHandler;
