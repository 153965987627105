import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import TopNavGeneric from "../components/Navs/TopNavMain";

import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Button";
import Nav from "react-bootstrap/Button";
import Media from "react-bootstrap/Media";
import { Context } from "../Context";
import { Storage, API, graphqlOperation } from "aws-amplify";
import GridLoader from "react-spinners/GridLoader";
import TextareaAutosize from "react-textarea-autosize";
import "../css/forms.css";
import {
  updateUser as UpdateUser,
  updateCompanion as UpdateCompanion,
} from "../graphql/mutations";
import {
  getUser as GetUser,
  getCompanion as GetCompanion,
} from "../graphql/queries";
import PageLoader from "../components/OtherComponents/LoaderPage";
import BottomNavEditProfile from "../components/Navs/BottomNavEditProfile";

// import { css } from "@emotion/core";

function EditProfile() {
  const { userType, id: userId } = useParams();
  const { user, userCompanions } = useContext(Context);
  const [profilePic, setProfilePic] = useState("");
  const [loading, setLoading] = useState(true);
  const [bioTextEdit, setBioTextEdit] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);
  const [userProfile, setUserProfile] = useState("");
  const [hasFood, setHasFood] = useState("");
  const [hasMedicalCare, setHasMedicalCare] = useState("");
  const [hasShelter, setHasShelter] = useState("");
  const history = useHistory();

  // console.log("hasFood", hasFood)
  // console.log("hasMedicalCare", hasMedicalCare)
  // console.log("hasShelter", hasShelter)

  useEffect(() => {
    setLoading(true);

    async function getProfileDetails(id) {
      try {
        if (userType === "companion") {
          const {
            data: { getCompanion },
          } = await API.graphql(graphqlOperation(GetCompanion, { id: id }));
          const profilePicData = await Storage.get(
            getCompanion.profilePicIcon.key
          );

          const initialFood =
            getCompanion.hasFood === null ? false : getCompanion.hasFood;
          const initialShelter =
            getCompanion.hasShelter === null ? false : getCompanion.hasShelter;
          const initialMedicalCare =
            getCompanion.hasMedicalCare === null
              ? false
              : getCompanion.hasMedicalCare;

          setUserProfile(getCompanion);
          setProfilePic(profilePicData);
          setHasFood(initialFood);
          setHasMedicalCare(initialMedicalCare);
          setHasShelter(initialShelter);
        } else {
          const {
            data: { getUser },
          } = await API.graphql(graphqlOperation(GetUser, { id: id }));
          const profilePicData = await Storage.get(getUser.profilePicIcon.key);
          setUserProfile(getUser);
          setProfilePic(profilePicData);
          // setUser(getUser);
        }
      } catch (err) {
        console.log("error: ", err);
      }
    }
    // check on edit permissions
    if (
      (userType === "guardian" && userId !== user.id) ||
      (userType === "companion" && !userCompanions.includes(userId))
    ) {
      history.push({
        pathname: `/guardian/${user.id}`,
        // state: {
        //   confirmationMessage: `Thanks ${newUser.username} for signing up! Check your email to verify your account. Once verified head back and log in.`
        // }
      });
    } else {
      getProfileDetails(userId);
    }

    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus(); //focuses on element on click
      var val = inputRef.current.value; //store the value of the element
      inputRef.current.value = ""; //clear the value of the element
      inputRef.current.value = val; //set that value back.
    }
  }, [isEditing]);

  async function handleSave() {
    setLoading(true);
    setIsEditing(!isEditing);
    // setBioTextEdit("");
    // setUserProfile("")
    try {
      if (userType === "companion") {
        const inputData = {
          id: userId,
          bio: bioTextEdit,
          hasFood: hasFood,
          hasShelter: hasShelter,
          hasMedicalCare: hasMedicalCare,
        };
        console.log("input", inputData);
        const {
          data: { updateCompanion },
        } = await API.graphql(
          graphqlOperation(UpdateCompanion, { input: inputData })
        );

        setUserProfile(updateCompanion);
      } else {
        const inputData = {
          id: userId,
          bio: bioTextEdit,
          bioAdded: true,
        };
        const {
          data: { updateUser },
        } = await API.graphql(
          graphqlOperation(UpdateUser, { input: inputData })
        );
        setUserProfile(updateUser);
      }
      setLoading(false);
    } catch (err) {
      console.log("error: ", err);
    }
  }

  function handleEditInfo() {
    setIsEditing(true);
    setBioTextEdit(userProfile.bio === null ? "" : userProfile.bio);
  }

  function handleCancel() {
    if (userType === "companion") {
      const initialFood =
        userProfile.hasFood === null ? false : userProfile.hasFood;
      const initialShelter =
        userProfile.hasShelter === null ? false : userProfile.hasShelter;
      const initialMedicalCare =
        userProfile.hasMedicalCare === null
          ? false
          : userProfile.hasMedicalCare;

      setHasFood(initialFood);
      setHasMedicalCare(initialShelter);
      setHasShelter(initialMedicalCare);
    }

    setIsEditing(false);
  }

  return (
    <>
      <TopNavGeneric
        title="Edit Profile"
        addLeftIcon={true}
        leftIconPath={"/settings/" + userType + "/" + userId}
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <PageLoader loading={loading} />
      {!loading && (
        <>
          <Container
            className="pt-5 mt-3 pb-3"
            style={{ display: loading ? "none" : "" }}
          >
            <Media className={"d-flex align-items-center"}>
              <img
                width={"70em"}
                height={"70em"}
                className="mr-3 rounded-circle"
                src={profilePic}
                // alt="Profile picture placeholder"
              />
              <Media.Body>
                <p>
                  {userType === "companion" ? userProfile.name : userProfile.id}
                </p>
              </Media.Body>
            </Media>
          </Container>
          <Container className="my-3">
            {!isEditing ? (
              <Row>
                <Col>
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    {userProfile.bio === null ? "No bio yet" : userProfile.bio}
                  </p>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <TextareaAutosize
                    ref={inputRef}
                    className={"textarea-autosize"}
                    minRows={3}
                    maxRows={6}
                    // defaultValue="Just a single line..."
                    value={bioTextEdit}
                    onChange={(e) => setBioTextEdit(e.target.value)}
                    autoFocus={false}
                    style={{ width: "100%" }}
                    maxLength={140}
                  />
                </Col>
              </Row>
            )}
            {userType === "companion" && (
              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label="Reliable Medical Care"
                    checked={hasMedicalCare}
                    onChange={() => setHasMedicalCare(!hasMedicalCare)}
                    disabled={!isEditing}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Reliable Shelter"
                    checked={hasShelter}
                    onChange={() => setHasShelter(!hasShelter)}
                    disabled={!isEditing}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Reliable Food"
                    checked={hasFood}
                    onChange={() => setHasFood(!hasFood)}
                    disabled={!isEditing}
                  />
                </Col>
              </Row>
            )}
            {/* {!isEditing ? (
              <Row>
                <Col>
                  <Button variant="primary" onClick={handleBioEdit}>
                    Edit bio
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <Button variant="danger" onClick={() => setIsEditing(false)}>
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button variant="success" onClick={saveUserBio}>
                    Save
                  </Button>
                </Col>
              </Row>
            )} */}
          </Container>
        </>
      )}
      <BottomNavEditProfile
        userType={userType}
        userId={userId}
        handleEditInfo={handleEditInfo}
        handleCancel={handleCancel}
        handleSave={handleSave}
        isEditing={isEditing}
      />
    </>
  );
}

export default EditProfile;
