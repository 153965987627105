import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import userAuthValidation from "../../hooks/userAuthValidation";

function SetNewPasswordHandler() {
  const history = useHistory();
  const [newPasswordObject, setNewPasswordObject] = useState({
    code: "",
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [error, setError] = useState({
    isError: false,
    message: ""
  });
  const [inputErrors, validation] = userAuthValidation();

  function handleChange(event) {
    const { name, value } = event.target;

    setNewPasswordObject({
      ...newPasswordObject,
      [name]: value
    });

    validation(name, value, newPasswordObject);
  }

  async function handleSubmit() {
    try {
      await Auth.forgotPasswordSubmit(
        newPasswordObject.email,
        newPasswordObject.code,
        newPasswordObject.password
      );
      history.push({
        pathname: "/auth-confirmation",
        state: {
          confirmationMessage: "Password changed! Head back and log in.",
          buttonText: "Log in",
          path: "/login"
        }
      });
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setError({
        isError: true,
        message: "Code or email entered was incorrect. Please try again."
      });
    }
  }
  return [newPasswordObject, error, inputErrors, handleChange, handleSubmit];
}

export default SetNewPasswordHandler;
