import { getConversation } from "./queries";

export const getUserConversations = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      conversations {
        items {
          conversation {
            id
            name
            updatedAt
          }
        }
      }
    }
  }
`;

export const getUserPosts = /* GraphQL */ `
  query GetUser($id: ID!, $nextToken: String) {
    getUser(id: $id) {
      posts(sortDirection: DESC, nextToken: $nextToken) {
        items {
          id
          posterId
          caption
          imgOrientation
          createdAt
          updatedAt
          postImg {
            key
          }
        }
        nextToken
      }
    }
  }
`;

export const getTaggedCompanionPosts = /* GraphQL */ `
  query GetCompanion($id: ID!, $nextToken: String) {
    getCompanion(id: $id) {
      postsTagged(sortDirection: DESC, nextToken: $nextToken) {
        items {
          post {
            id
            posterId
            caption
            imgOrientation
            createdAt
            updatedAt
            postImg {
              key
            }
          }
          
        }
        nextToken
      }
    }
  }
`;

export const getUserProfileInfo = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      username
      profilePic {
        key
      }
      profilePicIcon {
        key
      }
      profilePicTiny {
        key
      }
      posts {
        items {
          postId
          caption
          postImg {
            key
          }
        }
      }
    }
  }
`;

export const getConversationDetail = /* GraphQL */ `
  query GetConversation($id: ID!, $nextToken: String) {
    getConversation(id: $id) {
      id
      name
      messages(sortDirection: ASC, nextToken: $nextToken) {
        items {
          id
          content
          createdAt
          author {
            id
            preferred_username
          }
        }
        nextToken
      }
    }
  }
`;

export const getUserCompanionInfo = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      username
      companions {
        items {
          companion {
            id
            name
            bio
            profilePicIcon {
              key
            }
          }
        }
      }
    }
  }
`;

export const getUserFollowingInfo = /* GraphQL */ `
  query GetUser($id: ID!, $otherUserId: ID!) {
    getUser(id: $id) {
      username
      following(filter: { followLinkFollowingId: { eq: $otherUserId } }) {
        items {
          id
          followLinkFollowingId
        }
      }
    }
  }
`;

export const getNotificationsDetail = /* GraphQL */ `
  query GetNotificationsDetail($id: ID!, $nextToken: String, $limit: Int) {
    getUser(id: $id ) {
      id
      notifications(sortDirection: DESC, nextToken: $nextToken, limit: $limit) {
        items {
          id
          type
          createdAt
          createdBy {
            username
            profilePicIcon {
              key
            }
          }
          companion {
            name
          }
        }
        nextToken
      }
    }
  }
`;

export const getRequestDetail = /* GraphQL */ `
  query GetRequestDetail($id: ID!, $nextToken: String, $limit: Int) {
    getUser(id: $id) {
      id
      requests(sortDirection: DESC, nextToken: $nextToken, limit: $limit) {
        items {
          id
          type
          createdAt
          createdBy {
            username
            profilePicIcon {
              key
            }
          }
          companion {
            id
            name
            profilePicIcon {
              key
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const searchUsersDetail = /* GraphQL */ `
  query SearchUsersDetail(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        posts {
          nextToken
        }
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        bio
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          items {
            id
            type
            companion {
              id
              name
            }
          }
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
