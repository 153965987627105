import React, {useState} from "react";
import TextareaAutosize from "react-textarea-autosize";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import Media from "react-bootstrap/Media";

function CompanionInfoContainer(props) {


  return (
    <>
      <Container className="mt-5 pt-5">
        <Media>
          <img
            src={props.img}
            style={{
              width: props.width * props.imgSize,
              height: props.height * props.imgSize
            }}
            className="rounded-circle"
          />
          <Media.Body className="ml-2">
            <FormControl
              type="text"
              maxLength="50"
              placeholder="Companion's name"
              name="dog"
              value={props.nameValue}
              onChange={e => props.onChangeName(e.target.value)}
              required
            />
          </Media.Body>
        </Media>
      </Container>
      <Container className="mt-2">
        <Row>
          <Col>
            <TextareaAutosize
              className={"textarea-autosize"}
              minRows={3}
              maxRows={6}
              placeholder={"Add a bio"}
              maxLength="200"
              value={props.captionValue}
              onChange={e => props.onChange(e.target.value)}
              autoFocus={false}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
          <Form.Check 
        type="checkbox"
        label="Your companion"
        onChange={()=>props.setIsYourCompanion(!props.isYourCompanion)}
        checked={props.isYourCompanion}
      />
      <Form.Check 
        type="checkbox"
        label="Reliable Shelter"
        onChange={()=>props.setHasShelter(!props.hasShelter)}
        checked={props.hasShelter}
      />
      <Form.Check 
        type="checkbox"
        label="Reliable Food"
        onChange={()=>props.setHasFood(!props.hasFood)}
        checked={props.hasFood}
      />
      <Form.Check 
        type="checkbox"
        label="Reliable Medical Care"
        onChange={()=>props.setHasMedicalCare(!props.hasMedicalCare)}
        checked={props.hasMedicalCare}
      />

          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CompanionInfoContainer;
