import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import LinkNav from "../ButtonsLinks/LinkNav";
import { Context } from "../../Context";
import { Auth } from "aws-amplify";

function BottomNavGeneric(props) {
  const { user, updateAuthenticatedUser } = useContext(Context);
  const history = useHistory();

  async function handleSignupClick() {
    try {
      Auth.signOut();
      updateAuthenticatedUser(null, false);
      history.push("/signup")
    } catch (error) {
      console.log("Error: ", error);
    }
  }

  return (
    <>
      <Navbar
        className="bg-white border-top py-1 px-2"
        id="bottom-nav-generic"
        fixed="bottom"
      >
        <Nav className={user.id === "guest_user" ? "mx-auto" : "ml-auto"}>
          <LinkNav
            to="/posts"
            text="Explore"
            filled={props.postsIconFilled ? true : false}
          />
        </Nav>
        {user.id === "guest_user" ? (
          <Nav className="mx-auto">
            <Button className="px-4 py-1" onClick={handleSignupClick}>
              Sign up
            </Button>
          </Nav>
        ) : (
          <>
            <Nav className="mx-auto">
              <LinkNav
                to="/notifications"
                text="Alerts"
                filled={props.notificationsIconFilled ? true : false}
              />
            </Nav>
            <Nav className="mx-auto">
              <LinkNav
                to="/conversations"
                text="Chat"
                filled={props.chatIconFilled ? true : false}
              />
            </Nav>
            {/* <Nav className="mx-auto">
              <LinkNav to="/create-post" text="Post" />
            </Nav> */}
            <Nav className="mx-auto">
              <LinkNav to="/create-new-post" text="Post" />
            </Nav>
            <Nav className="mx-auto">
              <LinkNav
                to={`/companions/${user.id}`}
                text="Pals"
                filled={props.companionIconFilled ? true : false}
              />
            </Nav>
            <Nav className="ml-auto">
              <LinkNav
                to={`/guardian/${user.id}`}
                text="You"
                filled={props.guardianIconFilled ? true : false}
              />
            </Nav>
          </>
        )}
      </Navbar>
    </>
  );
}

export default BottomNavGeneric;
