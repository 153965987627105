import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import TopNavGeneric from "../../components/Navs/TopNavMain";
import ErrorMessageRow from "../../components/ContainersRows/RowErrorMessage";
import InstructionsMessageRow from "../../components/ContainersRows/RowInstructionsMessage";
import ForgotPasswordHandler from "../../handlers/AuthHandlers/ForgotPasswordHandler";
import SubmitButtonContainer from "../../components/ContainersRows/ContainerSubmitButton";

function ForgotPassword() {
  const [
    email,
    error,
    inputErrors,
    handleChange,
    handleSubmit
  ] = ForgotPasswordHandler();

  const row_mb = "mb-2";
  const isDisabled = !inputErrors.email.error ? false : true;

  return (
    <>
      <TopNavGeneric
        title="Forgot password"
        addLeftIcon={true}
        leftIconPath="/login"
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="my-5">
        <InstructionsMessageRow
          rowMargin={row_mb}
          text="Please enter your email and we will send you a link to change it"
        />
        <Row className={`${row_mb}`}>
          <Col>
            <FormControl
              className={inputErrors.email.valid}
              type="text"
              placeholder="Enter email"
              name="email"
              maxLength="50"
              value={email.email}
              onChange={handleChange}
            />
          </Col>
        </Row>
        {error.isError && (
          <ErrorMessageRow
            rowMargin={`${row_mb}`}
            message={error.errorMessage}
          />
        )}
      </Container>
      <SubmitButtonContainer
        text="Submit"
        rowClass={row_mb}
        handleSubmit={handleSubmit}
        disabled={isDisabled}
      />
    </>
  );
}

export default ForgotPassword;
