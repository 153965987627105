import React from "react";
import GridLoader from "react-spinners/GridLoader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function PageLoader(props) {
  return (
    <>
      {props.loading && (
        <Container className="pt-5">
          <Row className="pt-5">
            <Col className="d-flex justify-content-center">
              <GridLoader
                //   css={override}
                //   size={150}
                color={"#FF2277"}
                loading={props.loading}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default PageLoader;
