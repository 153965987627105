import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context";
import { API, graphqlOperation } from "aws-amplify";
import { createConversation, createConvoLink } from "../../graphql/mutations";

function StartConversationHandler() {
  const { user } = useContext(Context);
  const history = useHistory();
  const [newConversation, setNewConversation] = useState({
    name: ""
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setNewConversation({
      ...newConversation,
      [name]: value
    });
  }

  async function handleSubmit() {
    try {
      const createConversationResponse = await API.graphql(
        graphqlOperation(createConversation, {
          input: {
            name: newConversation.name,
            members: [user.username]
          }
        })
      );

      const conversationId =
        createConversationResponse.data.createConversation.id;
      await API.graphql(
        graphqlOperation(createConvoLink, {
          input: {
            convoLinkUserId: user.username,
            convoLinkConversationId: conversationId
          }
        })
      );

      setNewConversation({
        ...newConversation,
        name: ""
      });
      history.push("/conversations");
    } catch (error) {
      console.log("error:", error);
    }
  }

  return [newConversation, handleChange, handleSubmit];
}

export default StartConversationHandler;
