import React, { useState, useContext } from "react";
import {useParams} from "react-router-dom"
import NavTop from "../components/Navs/TopNavUpload";
import PageLoader from "../components/OtherComponents/LoaderPage";
import TextContainer from "../components/ContainersRows/ContainerText";
import NavBottom from "../components/Navs/BottomNavUpload";
import PostCaptionContainer from "../components/ContainersRows/ContainerPostCaption";
import CropImageHandler from "../handlers/ImageHandlers/CropImageHandler";
import Cropper from "react-easy-crop";
import "../css/crop.css";
import { Context } from "../Context";

function EditProfilePicture() {
  const { userType, id: userId } = useParams();

  const isProfile = true;
  const isCompanion = userType=== "companion" ? true : false;

  const { user } = useContext(Context);
  //   const isProfile = props.isProfile;
  const [widthAspect, setWidthAspect] = useState(4);
  const [heightAspect, setHeightAspect] = useState(3);
  //   const [aspect, setAspect] = useState(4 / 3);
  const [orientBtnText, setOrientBtnText] = useState("Landscape");
  const [page, setPage] = useState(1);

  const [
    handleChange,
    handleRotation,
    crop,
    setCrop,
    loading,
    zoom,
    setZoom,
    uploadFileS3,
    onCropComplete,
    imgSrc,
    rotation,
    croppedImg,
    getCroppedFile,
    caption,
    setCaption
  ] = CropImageHandler(isProfile, isCompanion, userType, userId);

  function handleDimension() {
    if (orientBtnText === "Landscape") {
      console.log("setting to portrait");
      setWidthAspect(3);
      setHeightAspect(4);
      setOrientBtnText("Portrait");
    } else {
      console.log("setting to landscape");
      setWidthAspect(4);
      setHeightAspect(3);
      setOrientBtnText("Landscape");
    }
  }

  async function handleNext() {
    await getCroppedFile();
    setPage(page => page + 1);
  }

  function handleBack() {
    setPage(page => page - 1);
  }

  //   const title = isProfile ? "Edit profile picture" : "Create post";
  const cancelLink = isProfile ? "/edit-profile/"+userType+"/"+userId : "/guardian/" + user.id;
  const isDisabled = imgSrc === null || page !== 1 ? true : false;
  const isAddPhotoDisabled = page !== 1 ? true : false;

  return (
    <>
      <NavTop
        title="Edit profile picture"
        isDisabled={isDisabled}
        isLeftLink={true}
        leftText={"Cancel"}
        rightText={"Save"}
        leftLink={cancelLink}
        rightOnClick={uploadFileS3}
      />
      <PageLoader loading={loading} />

      {!loading &&
        (imgSrc === null ? (
          <TextContainer text="Add a photo" />
        ) : (
          <div className="crop-container">
            <Cropper
              image={imgSrc}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={isProfile ? 1 : widthAspect / heightAspect}
              cropShape={isProfile ? "round" : "rect"}
              showGrid={true}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              restrictPosition={false}
            />
          </div>
        ))}
      <NavBottom
        isDisabled={isDisabled}
        addPhotoBtnText={imgSrc === null ? "Add" : "Change"}
        removeOrientationBtn={true}
        isAddPhotoDisabled={isAddPhotoDisabled}
        handleChange={handleChange}
        handleRotation={handleRotation}
        handleDimension={handleDimension}
      />
    </>
    // <AddEditImagesContainer isProfile={isProfile} isCompanion={isCompanion}/>
  );
}

export default EditProfilePicture;
