import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import TopNavGeneric from "../../components/Navs/TopNavMain";
import StartConversationHandler from "../../handlers/ConversationHandlers/StartConversationHandler";
import SubmitButtonContainer from "../../components/ContainersRows/ContainerSubmitButton";

function StartConversation() {
  const [
    newConversation,
    handleChange,
    handleSubmit
  ] = StartConversationHandler();

  const row_mb = "mb-2";
  const isDisabled = newConversation.name.length > 3 ? false : true;

  return (
    <>
      <TopNavGeneric
        title="Start conversation"
        addLeftIcon={true}
        leftIconPath="/conversations"
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="my-5">
        <Row className={row_mb}>
          <Col>
            <FormControl
              className=""
              type="text"
              placeholder="Enter conversation name"
              name="name"
              value={newConversation.name}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Container>
      <SubmitButtonContainer
        text="Submit"
        rowClass={row_mb}
        handleSubmit={handleSubmit}
        disabled={isDisabled}
      />
    </>
  );
}

export default StartConversation;
