import React, { useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import LinkNav from "../ButtonsLinks/LinkNav";
import { Context } from "../../Context";
import { Link } from "react-router-dom";

function BottomNavEditProfile(props) {
  const { user } = useContext(Context);

  return (
    <>
      <Navbar
        className="bg-white border-top py-4"
        id="bottom-nav-generic"
        fixed="bottom"
      >
        <Nav className="mr-auto">
          {!props.isEditing ? (
            <Button onClick={props.handleEditInfo}>Edit Info</Button>
          ) : (
            <Button variant="danger" onClick={props.handleCancel}>
              Cancel
            </Button>
          )}
        </Nav>
        <Nav className="ml-auto">
          {!props.isEditing ? (
            <Link
              to={
                "/edit-profile-picture/" + props.userType + "/" + props.userId
              }
            >
              <Button className="px-3">Edit Profile Picture</Button>
            </Link>
          ) : (
            <Button variant="success" onClick={props.handleSave}>
              Save
            </Button>
          )}
        </Nav>
      </Navbar>
    </>
  );
}

export default BottomNavEditProfile;
