/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      user_id
      username
      preferred_username
      email
      conversations {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      postAdded
      posts {
        items {
          id
          posterId
          caption
          imgOrientation
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      profilePicLink
      awsIdentityId
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicAdded
      bio
      bioAdded
      followers {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationsCreated {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      requestsCreated {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      companions {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      companionAdded
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      user_id
      username
      preferred_username
      email
      conversations {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      postAdded
      posts {
        items {
          id
          posterId
          caption
          imgOrientation
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      profilePicLink
      awsIdentityId
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicAdded
      bio
      bioAdded
      followers {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationsCreated {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      requestsCreated {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      companions {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      companionAdded
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      user_id
      username
      preferred_username
      email
      conversations {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      postAdded
      posts {
        items {
          id
          posterId
          caption
          imgOrientation
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      profilePicLink
      awsIdentityId
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicAdded
      bio
      bioAdded
      followers {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationsCreated {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      requestsCreated {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      companions {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      companionAdded
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      userToNotify {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      userToNotifyId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      userToNotify {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      userToNotifyId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      userToNotify {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      userToNotifyId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      createdAt
      updatedAt
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      requestedUser {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      requestedUserId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      accepted
      current
      createdAt
      updatedAt
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      requestedUser {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      requestedUserId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      accepted
      current
      createdAt
      updatedAt
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      requestedUser {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      requestedUserId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      accepted
      current
      createdAt
      updatedAt
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      associated {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      associated {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      associated {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      author {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      authorId
      content
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      author {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      authorId
      content
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      author {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      authorId
      content
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      createdAt
      updatedAt
    }
  }
`;
export const createConvoLink = /* GraphQL */ `
  mutation CreateConvoLink(
    $input: CreateConvoLinkInput!
    $condition: ModelConvoLinkConditionInput
  ) {
    createConvoLink(input: $input, condition: $condition) {
      id
      user {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      convoLinkUserId
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const updateConvoLink = /* GraphQL */ `
  mutation UpdateConvoLink(
    $input: UpdateConvoLinkInput!
    $condition: ModelConvoLinkConditionInput
  ) {
    updateConvoLink(input: $input, condition: $condition) {
      id
      user {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      convoLinkUserId
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const deleteConvoLink = /* GraphQL */ `
  mutation DeleteConvoLink(
    $input: DeleteConvoLinkInput!
    $condition: ModelConvoLinkConditionInput
  ) {
    deleteConvoLink(input: $input, condition: $condition) {
      id
      user {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      convoLinkUserId
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      poster {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      companionsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      posterId
      postImg {
        bucket
        region
        key
      }
      caption
      imgOrientation
      postType
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      poster {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      companionsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      posterId
      postImg {
        bucket
        region
        key
      }
      caption
      imgOrientation
      postType
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      poster {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      companionsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      posterId
      postImg {
        bucket
        region
        key
      }
      caption
      imgOrientation
      postType
      createdAt
      updatedAt
    }
  }
`;
export const createCompanion = /* GraphQL */ `
  mutation CreateCompanion(
    $input: CreateCompanionInput!
    $condition: ModelCompanionConditionInput
  ) {
    createCompanion(input: $input, condition: $condition) {
      id
      name
      bio
      hasShelter
      hasFood
      hasMedicalCare
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicLink
      guardians {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      postsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanion = /* GraphQL */ `
  mutation UpdateCompanion(
    $input: UpdateCompanionInput!
    $condition: ModelCompanionConditionInput
  ) {
    updateCompanion(input: $input, condition: $condition) {
      id
      name
      bio
      hasShelter
      hasFood
      hasMedicalCare
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicLink
      guardians {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      postsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanion = /* GraphQL */ `
  mutation DeleteCompanion(
    $input: DeleteCompanionInput!
    $condition: ModelCompanionConditionInput
  ) {
    deleteCompanion(input: $input, condition: $condition) {
      id
      name
      bio
      hasShelter
      hasFood
      hasMedicalCare
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicLink
      guardians {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      postsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGuardianshipLink = /* GraphQL */ `
  mutation CreateGuardianshipLink(
    $input: CreateGuardianshipLinkInput!
    $condition: ModelGuardianshipLinkConditionInput
  ) {
    createGuardianshipLink(input: $input, condition: $condition) {
      id
      guardian {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      guardianshipLinkGuardianId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      guardianshipLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const updateGuardianshipLink = /* GraphQL */ `
  mutation UpdateGuardianshipLink(
    $input: UpdateGuardianshipLinkInput!
    $condition: ModelGuardianshipLinkConditionInput
  ) {
    updateGuardianshipLink(input: $input, condition: $condition) {
      id
      guardian {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      guardianshipLinkGuardianId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      guardianshipLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const deleteGuardianshipLink = /* GraphQL */ `
  mutation DeleteGuardianshipLink(
    $input: DeleteGuardianshipLinkInput!
    $condition: ModelGuardianshipLinkConditionInput
  ) {
    deleteGuardianshipLink(input: $input, condition: $condition) {
      id
      guardian {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      guardianshipLinkGuardianId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      guardianshipLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const createTaggedLink = /* GraphQL */ `
  mutation CreateTaggedLink(
    $input: CreateTaggedLinkInput!
    $condition: ModelTaggedLinkConditionInput
  ) {
    createTaggedLink(input: $input, condition: $condition) {
      id
      post {
        id
        poster {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        companionsTagged {
          nextToken
        }
        posterId
        postImg {
          bucket
          region
          key
        }
        caption
        imgOrientation
        postType
        createdAt
        updatedAt
      }
      taggedLinkPostId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      taggedLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const updateTaggedLink = /* GraphQL */ `
  mutation UpdateTaggedLink(
    $input: UpdateTaggedLinkInput!
    $condition: ModelTaggedLinkConditionInput
  ) {
    updateTaggedLink(input: $input, condition: $condition) {
      id
      post {
        id
        poster {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        companionsTagged {
          nextToken
        }
        posterId
        postImg {
          bucket
          region
          key
        }
        caption
        imgOrientation
        postType
        createdAt
        updatedAt
      }
      taggedLinkPostId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      taggedLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaggedLink = /* GraphQL */ `
  mutation DeleteTaggedLink(
    $input: DeleteTaggedLinkInput!
    $condition: ModelTaggedLinkConditionInput
  ) {
    deleteTaggedLink(input: $input, condition: $condition) {
      id
      post {
        id
        poster {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        companionsTagged {
          nextToken
        }
        posterId
        postImg {
          bucket
          region
          key
        }
        caption
        imgOrientation
        postType
        createdAt
        updatedAt
      }
      taggedLinkPostId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      taggedLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const createFollowLink = /* GraphQL */ `
  mutation CreateFollowLink(
    $input: CreateFollowLinkInput!
    $condition: ModelFollowLinkConditionInput
  ) {
    createFollowLink(input: $input, condition: $condition) {
      id
      following {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowingId
      follower {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowerId
      createdAt
      updatedAt
    }
  }
`;
export const updateFollowLink = /* GraphQL */ `
  mutation UpdateFollowLink(
    $input: UpdateFollowLinkInput!
    $condition: ModelFollowLinkConditionInput
  ) {
    updateFollowLink(input: $input, condition: $condition) {
      id
      following {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowingId
      follower {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowerId
      createdAt
      updatedAt
    }
  }
`;
export const deleteFollowLink = /* GraphQL */ `
  mutation DeleteFollowLink(
    $input: DeleteFollowLinkInput!
    $condition: ModelFollowLinkConditionInput
  ) {
    deleteFollowLink(input: $input, condition: $condition) {
      id
      following {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowingId
      follower {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowerId
      createdAt
      updatedAt
    }
  }
`;
