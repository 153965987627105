import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import {
  MdCropLandscape,
  MdCropPortrait,
  MdCropRotate,
  MdAddAPhoto,
  MdArrowForward,
  MdArrowBack,
  MdCheck,
  MdClose
} from "react-icons/md";
import ButtonNav from "../ButtonsLinks/ButtonNav";
import LinkNav from "../ButtonsLinks/LinkNav";

function NavTop(props) {
  return (
    <>
      <Navbar className="bg-white border-bottom  px-1 py-1" fixed="top">
        <Nav className="mr-auto">
          {props.isLeftLink ? (
            <LinkNav to={props.leftLink} text={props.leftText} />
          ) : (
            <ButtonNav
              onClick={props.leftOnClick}
              text={props.leftText}
              isDisabled={props.isDisabled}
            />
          )}
        </Nav>
        <Navbar.Brand className="mx-auto">{props.title}</Navbar.Brand>
        <Nav className="ml-auto">
          <ButtonNav
            onClick={props.rightOnClick}
            text={props.rightText}
            isDisabled={props.isDisabled}
          />
        </Nav>
      </Navbar>
    </>
  );
}

export default NavTop;
