import React from "react";
import blackdog from "../../static/blackdog.jpg";
import MessageContainer from "./ContainerMessage";
import DisplayMessagesHandler from "../../handlers/ConversationHandlers/DisplayMessagesHandler";

function AllMessagesContainer(props) {
  const [messages, messagesEndRef] = DisplayMessagesHandler(props.chatId);

  let messagesComponent = messages.map(message => {
    return (
      <MessageContainer
        key={message.id}
        img={blackdog}
        name={message.author.preferred_username}
        date={new Date(message.createdAt)}
        content={message.content}
      />
    );
  });

  return (
    <>
      {messagesComponent}
      <div ref={messagesEndRef} />
    </>
  );
}

export default AllMessagesContainer;
