import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import TopNavGeneric from "../../components/Navs/TopNavMain";
import InstructionsMessageRow from "../../components/ContainersRows/RowInstructionsMessage";

function AuthConfirmation() {
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [buttonText, setButtonText] = useState("Back to home");
  const [path, setPath] = useState("/");

  const location = useLocation();
  useEffect(() => {
    if (typeof location.state !== "undefined") {
      setConfirmationMessage(location.state.confirmationMessage);
      setButtonText(location.state.buttonText);
      setPath(location.state.path);
    }
  }, [location]);
  return (
    <>
      <TopNavGeneric title="Confirmation" />
      <Container className="py-5">
        <InstructionsMessageRow rowMargin="pt-5 mb-2" text={confirmationMessage} />
        <Row className="mb-2">
          <Col className="text-center">
            <Link to={path}>
              <Button className="px-5">{buttonText}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AuthConfirmation;
