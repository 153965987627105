/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_id
      username
      preferred_username
      email
      conversations {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      postAdded
      posts {
        items {
          id
          posterId
          caption
          imgOrientation
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      profilePicLink
      awsIdentityId
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicAdded
      bio
      bioAdded
      followers {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationsCreated {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      requestsCreated {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      companions {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      companionAdded
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      userToNotify {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      userToNotifyId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdBy {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        createdById
        userToNotify {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        userToNotifyId
        companion {
          id
          name
          bio
          hasShelter
          hasFood
          hasMedicalCare
          profilePicLink
          createdAt
          updatedAt
        }
        companionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      requestedUser {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      requestedUserId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      accepted
      current
      createdAt
      updatedAt
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdBy {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        createdById
        requestedUser {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        requestedUserId
        companion {
          id
          name
          bio
          hasShelter
          hasFood
          hasMedicalCare
          profilePicLink
          createdAt
          updatedAt
        }
        companionId
        accepted
        current
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      associated {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      author {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      authorId
      content
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        author {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        authorId
        content
        conversation {
          id
          name
          members
          createdAt
          updatedAt
        }
        messageConversationId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConvoLink = /* GraphQL */ `
  query GetConvoLink($id: ID!) {
    getConvoLink(id: $id) {
      id
      user {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      convoLinkUserId
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const listConvoLinks = /* GraphQL */ `
  query ListConvoLinks(
    $filter: ModelConvoLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConvoLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        convoLinkUserId
        conversation {
          id
          name
          members
          createdAt
          updatedAt
        }
        convoLinkConversationId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      poster {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      companionsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      posterId
      postImg {
        bucket
        region
        key
      }
      caption
      imgOrientation
      postType
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        poster {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        companionsTagged {
          nextToken
        }
        posterId
        postImg {
          bucket
          region
          key
        }
        caption
        imgOrientation
        postType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanion = /* GraphQL */ `
  query GetCompanion($id: ID!) {
    getCompanion(id: $id) {
      id
      name
      bio
      hasShelter
      hasFood
      hasMedicalCare
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicLink
      guardians {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      postsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanions = /* GraphQL */ `
  query ListCompanions(
    $filter: ModelCompanionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGuardianshipLink = /* GraphQL */ `
  query GetGuardianshipLink($id: ID!) {
    getGuardianshipLink(id: $id) {
      id
      guardian {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      guardianshipLinkGuardianId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      guardianshipLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const listGuardianshipLinks = /* GraphQL */ `
  query ListGuardianshipLinks(
    $filter: ModelGuardianshipLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuardianshipLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        guardian {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        guardianshipLinkGuardianId
        companion {
          id
          name
          bio
          hasShelter
          hasFood
          hasMedicalCare
          profilePicLink
          createdAt
          updatedAt
        }
        guardianshipLinkCompanionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTaggedLink = /* GraphQL */ `
  query GetTaggedLink($id: ID!) {
    getTaggedLink(id: $id) {
      id
      post {
        id
        poster {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        companionsTagged {
          nextToken
        }
        posterId
        postImg {
          bucket
          region
          key
        }
        caption
        imgOrientation
        postType
        createdAt
        updatedAt
      }
      taggedLinkPostId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      taggedLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const listTaggedLinks = /* GraphQL */ `
  query ListTaggedLinks(
    $filter: ModelTaggedLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaggedLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post {
          id
          posterId
          caption
          imgOrientation
          postType
          createdAt
          updatedAt
        }
        taggedLinkPostId
        companion {
          id
          name
          bio
          hasShelter
          hasFood
          hasMedicalCare
          profilePicLink
          createdAt
          updatedAt
        }
        taggedLinkCompanionId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollowLink = /* GraphQL */ `
  query GetFollowLink($id: ID!) {
    getFollowLink(id: $id) {
      id
      following {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowingId
      follower {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowerId
      createdAt
      updatedAt
    }
  }
`;
export const listFollowLinks = /* GraphQL */ `
  query ListFollowLinks(
    $filter: ModelFollowLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        following {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        followLinkFollowingId
        follower {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        followLinkFollowerId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByPosterId = /* GraphQL */ `
  query PostsByPosterId(
    $posterId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByPosterId(
      posterId: $posterId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        poster {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        companionsTagged {
          nextToken
        }
        posterId
        postImg {
          bucket
          region
          key
        }
        caption
        imgOrientation
        postType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByPostType = /* GraphQL */ `
  query PostsByPostType(
    $postType: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByPostType(
      postType: $postType
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        poster {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        companionsTagged {
          nextToken
        }
        posterId
        postImg {
          bucket
          region
          key
        }
        caption
        imgOrientation
        postType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCompanions = /* GraphQL */ `
  query SearchCompanions(
    $filter: SearchableCompanionFilterInput
    $sort: SearchableCompanionSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchCompanions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
