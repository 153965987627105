import React, { useContext, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-bootstrap/Media";
import { Link, useParams } from "react-router-dom";
import TopNavGeneric from "../components/Navs/TopNavMain";
import BottomNavGeneric from "../components/Navs/BottomNavMain";
import { Context } from "../Context";
import Button from "react-bootstrap/Button";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { getUserCompanionInfo as GetUserCompanionInfo } from "../graphql/customQueries";
import { IoIosArrowForward } from "react-icons/io";

function Companions() {
  //   const { user } = useContext(Context);
  const [companions, setCompanions] = useState([]);
  const { guardianId } = useParams();
  //   console.log('guardianId', guardianId)

  useEffect(() => {
    async function getCompanionsDetails(id) {
      try {
        const userCompanionsResponse = await API.graphql(
          graphqlOperation(GetUserCompanionInfo, { id: id })
        );

        const companionsInfo =
          userCompanionsResponse.data.getUser.companions.items;

        const companionsObj = companionsInfo.map(async ({ companion }) => {
          console.log(companion);
          const key = companion.profilePicIcon.key;
          const profPic = await Storage.get(key);
          // console.log(key, profPic)
          //   console.log({ ...companion, profPic });
          return { ...companion, profPic };
        });

        Promise.all(companionsObj).then((results) => {
          //   console.log("results", results);
          setCompanions(results);
          //   return results
        });

        console.log("items", userCompanionsResponse.data.getUser.companions);
      } catch (err) {
        console.log("error: ", err);
      }
    }

    getCompanionsDetails(guardianId);
  }, []);

  console.log("companions", companions);

  let companionsComponent = companions.map((companion) => {
    return (
      <Media key={companion.id} className="border-bottom py-1">
        <img
          width={"40rem"}
          height={"40rem"}
          className="mr-3 rounded-circle"
          src={companion.profPic}
          alt="koh tao animal clinic"
        />
        <Media.Body>
          <Link to={`/companion/` + companion.id} className="">
            <Container className="px-0">
              <Row className="ml-auto mr-auto">
                <Col className="col-10">
                  <h6 className="d-inline text-dark">{companion.name}</h6>
                </Col>

                <Col className="col-2 text-right">
                  <IoIosArrowForward
                    size="1.5em"
                    className="text-info text-right"
                  />
                </Col>
              </Row>
            </Container>
          </Link>
        </Media.Body>
      </Media>
    );
  });

  return (
    <>
      <TopNavGeneric
        title="Companions"
        // addRightIcon={true}
        disableRightIcon={true}
        // rightIconPath="/settings"
        rightIcon="MdSettings"
        disableLeftIcon={true}
        leftIcon="MdSettings"
      />
      <Container className="py-5">
      <Row className="pt-3 pb-5">
          <Col>
          {companionsComponent}
          </Col>
        </Row>
      <Row>
          <Col className="text-center">
          <Link to="/add-companion"><Button variant="info" className="px-5 py-1"> Add companion
            </Button></Link>
          </Col>
        </Row>
       
        
        </Container>
      <BottomNavGeneric companionIconFilled={true} />
    </>
  );
}

export default Companions;
