import React from "react";
import Container from "react-bootstrap/Container";
import { FormattedDateParts } from "react-intl";
import Media from "react-bootstrap/Media";

function MessageContainer(props) {
  return (
    <>
      <Container className="px-0">
        <Media>
          <img
            width={50}
            height={50}
            className="mr-3 rounded"
            src={props.img}
            alt="Generic placeholder"
          />
          <Media.Body>
            <h6 className="d-inline pr-2">{props.name}</h6>

            <FormattedDateParts
              className="d-inline"
              value={new Date(props.date)}
              month="numeric"
              year="numeric"
              day="2-digit"
              hour="numeric"
              minute="numeric"
              hour12={false}
            >
              {parts => (
                <>
                  <small>
                    {parts[4].value.slice(0)}-{parts[0].value}-{parts[2].value}
                    {" | "}
                    {parts[6].value}:{parts[8].value}
                  </small>
                </>
              )}
            </FormattedDateParts>
            <p className="text-muted">{props.content}</p>
          </Media.Body>
        </Media>
      </Container>
    </>
  );
}

export default MessageContainer;
