import { useContext } from "react";
import "../../css/home.css";
import { Context } from "../../Context";
import { Auth } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "../../graphql/queries";
import { useHistory } from "react-router-dom";

function GuestLoginHandler() {
  const { updateAuthenticatedUser, setIsGuest } = useContext(Context);
  const history = useHistory();

  async function handleGuestLogin() {
    try {
      // sign in as guest
      const user = await Auth.signIn({
        username: "guest_user",
        password: "123456",
      });

      // Check if user in database
      const getUserResponse = await API.graphql(
        graphqlOperation(getUser, { id: user.username })
      );
      console.log("getUserResponse:", getUserResponse);

      // update authenticated user and set guest to true
      updateAuthenticatedUser(getUserResponse.data.getUser, true);
      setIsGuest(true);

      history.push("/posts");
      
    } catch (err) {
      console.log("error:", err);
    }
  }

  return [handleGuestLogin];
}

export default GuestLoginHandler;
