import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import TopNavGeneric from "../../components/Navs/TopNavMain";
import ErrorMessageRow from "../../components/ContainersRows/RowErrorMessage";
import ConfirmSignupHandler from "../../handlers/AuthHandlers/ConfirmSignupHandler";
import SubmitButtonContainer from "../../components/ContainersRows/ContainerSubmitButton";
import InstructionsMessageRow from "../../components/ContainersRows/RowInstructionsMessage";

function ConfirmSignup() {
  const [
    newUser,
    error,
    inputErrors,
    confirmationMessage,
    handleChange,
    handleSubmit
  ] = ConfirmSignupHandler();

  const row_mb = "mb-2";

  const isDisabled =
    !inputErrors.code.error & !inputErrors.username.error ? false : true;

  return (
    <>
      <TopNavGeneric
        title="Confirm sign up"
        addLeftIcon={true}
        leftIconPath="/"
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="py-5">
        <InstructionsMessageRow rowMargin="pt-5 mb-2" text={confirmationMessage} />
        <Row className={row_mb}>
          <Col>
            <FormControl
              className={inputErrors.code.valid}
              type="text"
              placeholder="Enter code"
              name="code"
              maxLength="10"
              value={newUser.code}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className={row_mb}>
          <Col>
            <FormControl
              className={inputErrors.username.valid}
              type="text"
              placeholder="Enter username"
              name="username"
              maxLength="50"
              value={newUser.username}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className={row_mb}>
          <Col>
            <div className="d-flex justify-content-start">
              <Link to="/resend-code" className="text-info">
                <p>Resend code?</p>
              </Link>
            </div>
          </Col>
        </Row>
        {error.isError && (
          <ErrorMessageRow rowMargin={`${row_mb}`} message={error.message} />
        )}
      </Container>
      <SubmitButtonContainer
        text="Submit"
        rowClass={row_mb}
        handleSubmit={handleSubmit}
        disabled={isDisabled}
      />
    </>
  );
}

export default ConfirmSignup;
