import React, { useContext, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link, useParams } from "react-router-dom";
import TopNavGeneric from "../components/Navs/TopNavMain";
import BottomNavGeneric from "../components/Navs/BottomNavMain";
import { Context } from "../Context";
import Button from "react-bootstrap/Button";
import Media from "react-bootstrap/Media";
import FormControl from "react-bootstrap/FormControl";
import { Storage, API, graphqlOperation, Auth } from "aws-amplify";
import {
  getUser as GetUser,
  getPost as GetPost,
  searchUsers as SearchUsers,
} from "../graphql/queries";
import {
  // updateUser as UpdateUser,
  createFollowLink as CreateFollowLink,
  deleteFollowLink as DeleteFollowLink,
  createNotification as CreateNotification,
} from "../graphql/mutations";
import {
  // updateUser as UpdateUser,
  getUserFollowingInfo as GetUserFollowingInfo,
  getUserPosts as GetUserPosts,
} from "../graphql/customQueries";
import { useHistory } from "react-router-dom";
import PageLoader from "../components/OtherComponents/LoaderPage";

function GuardianProfile() {
  const history = useHistory();
  const { user } = useContext(Context);
  const [userProfile, setUserProfile] = useState("");
  const [profilePic, setProfilePic] = useState("");
  // const [postPic, setPostPic] = useState("");
  const [loading, setLoading] = useState(true);
  const { guardianId } = useParams();
  const [isFollowing, setIsFollowing] = useState(false);
  const [followingId, setFollowingId] = useState(null);
  const [posts, setPosts] = useState([]);
  const [followersCount, setFollowersCount] = useState(0);
  // const userId = guardianId === void 0 ? user.id : guardianId;

  console.log("guardianId", guardianId);

  useEffect(() => {
    setLoading(true);

    async function getProfileDetails(guardianId, userId) {
      try {
        const test = await Storage.get('9ab12fec-958f-450c-b425-770adca15054generic_profile_pic.jpeg',
         { level: 'protected', identityId:'us-west-2:f96f457e-ef64-4ae0-9100-8238a69addb8' });

         console.log('test', test)

         const authTest = await Auth.currentCredentials()

         console.log('authTest', authTest)
        

        const {
          data: {
            getUser: {
              following: { items },
            },
          },
        } = await API.graphql(
          graphqlOperation(GetUserFollowingInfo, {
            id: userId,
            otherUserId: guardianId,
          })
        );
        if (items.length === 1) {
          setIsFollowing(true);
          setFollowingId(items[0].id);
        }

        const {
          data: { getUser },
        } = await API.graphql(graphqlOperation(GetUser, { id: guardianId }));
        setUserProfile(getUser);
        console.log("getUser", getUser);

        const countFollowers = getUser.followers.items.length;

        setFollowersCount(countFollowers);

        // const profilePicData = await Storage.get(getUser.profilePicIcon.key, { level: 'protected', identityId:'us-west-2:f96f457e-ef64-4ae0-9100-8238a69addb8' });
        // console.log('profilePicData', profilePicData)
        // const testProfPic = 'https://beaukohtao-content91826-kohenv.s3-us-west-2.amazonaws.com/public/ffdaa4df-3441-46dd-b979-7f8c8e64ad2dboboIMG_3224.JPG'
        setProfilePic(getUser.profilePicLink);

        const getUserPostsResponse = await API.graphql(
          graphqlOperation(GetUserPosts, { id: guardianId })
        );
        // setUserProfile(getUser);
        console.log("getUserPostsResponse", getUserPostsResponse);

        const postInfo = getUserPostsResponse.data.getUser.posts.items;

        console.log("postInfo", postInfo);

        let postPic = "";
        if (postInfo.length > 0) {
          const postObj = postInfo.map(async (post) => {
            const key = post.postImg.key;
            postPic = await Storage.get(key);
            return { ...post, postPic };
          });

          Promise.all(postObj).then((results) => {
            //   console.log("results", results);
            setPosts(results);
            //   return results
          });
        } else {
          setPosts([]);
        }
      } catch (err) {
        console.log("error: ", err);
      }
    }

    getProfileDetails(guardianId, user.id);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [guardianId]);

  async function handleFollow() {
    try {
      if (isFollowing) {
        const followResponse = await API.graphql(
          graphqlOperation(DeleteFollowLink, {
            input: {
              id: followingId,
            },
          })
        );

        console.log("deleteFollowResponse", followResponse);
        setIsFollowing(false);
        setFollowingId(null);
        setFollowersCount(followersCount - 1);

        const notificationResponse = await API.graphql(
          graphqlOperation(CreateNotification, {
            input: {
              type: "unfollow",
              createdById: user.id,
              userToNotifyId: guardianId,
            },
          })
        );

        console.log("notificationResponseUnFollow", notificationResponse);
      } else {
        const followResponse = await API.graphql(
          graphqlOperation(CreateFollowLink, {
            input: {
              followLinkFollowingId: guardianId,
              followLinkFollowerId: user.id,
            },
          })
        );

        console.log("followResponse", followResponse);
        setIsFollowing(true);
        setFollowingId(followResponse.data.createFollowLink.id);
        setFollowersCount(followersCount + 1);

        const notificationResponse = await API.graphql(
          graphqlOperation(CreateNotification, {
            input: {
              type: "follow",
              createdById: user.id,
              userToNotifyId: guardianId,
            },
          })
        );

        console.log("notificationResponseFollow", notificationResponse);
      }
    } catch (err) {
      console.log("error: ", err);
    }
  }

  const width = "100%";
  // const height = "100%";

  let posts0Component = posts
    .filter((post, index) => {
      return index % 3 === 0;
    })
    .map((post) => {
      return (
        <div key={post.id} className="pb-1 px-1">
          <img width={width} className="" src={post.postPic} />
        </div>
      );
    });

  let posts1Component = posts
    .filter((post, index) => {
      return (index - 1) % 3 === 0;
    })
    .map((post) => {
      return (
        <div key={post.id} className="pb-1">
          <img width={width} className="" src={post.postPic} />
        </div>
      );
    });

  let posts2Component = posts
    .filter((post, index) => {
      return (index + 1) % 3 === 0;
    })
    .map((post) => {
      return (
        <div key={post.id} className="pb-1 px-1">
          <img width={width} className="" src={post.postPic} />
        </div>
      );
    });

  console.log("userProfile", userProfile.followers === undefined);
  console.log("followersCount", followersCount);

  const constainerShow = loading ? "pt-5 mb-5 pb-5 d-none" : "pt-5 mb-5 pb-5";
  const showSettings = guardianId === user.id ? true : false;

  return (
    <>
      <TopNavGeneric
        title={guardianId}
        // profPic={profilePic}
        addRightIcon={showSettings}
        rightIconPath={"/settings/guardian/" + guardianId}
        rightIcon="MdSettings"
        addLeftIcon={showSettings}
        disableLeftIcon={true}
        leftIconPath={"#"}
        leftIcon="MdSearch"
      />
      <PageLoader loading={loading} />

      <Container className={constainerShow}>
        <Media className="pb-3">
          <img
            width={"70rem"}
            height={"70rem"}
            className="mr-2 rounded-circle"
            src={profilePic}
          />
          <Media.Body className="align-self-center">
            <Container className="p-0 ">
              <Row className="ml-auto mr-auto d-inline">
                <Container className="p-0 text-center d-inline">
                  <Button variant="white" className="p-0">
                    <Container>
                      <Row className="">
                        <Col className="p-0 text-center">
                          <p
                            className="m-0 font-weight-bold"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {userProfile.companions === undefined
                              ? 0
                              : userProfile.companions.items.length}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-0 text-center">
                          <p className="m-0" style={{ fontSize: "0.8rem" }}>
                            Companions
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </Button>
                </Container>
                <Container className="p-0 text-center d-inline mx-2">
                  <Button variant="white" className="p-0">
                    <Container>
                      <Row className="">
                        <Col className="p-0 text-center">
                          <p
                            className="m-0 font-weight-bold"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {followersCount}
                            {/* {userProfile.followers === undefined
                              ? 0
                              : userProfile.followers.items.length} */}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-0 text-center">
                          <p className="m-0" style={{ fontSize: "0.8rem" }}>
                            Followers
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </Button>
                </Container>
                <Container className="p-0 text-center d-inline">
                  <Button variant="white" className="p-0">
                    <Container>
                      <Row className="">
                        <Col className="p-0 text-center">
                          <p
                            className="m-0 font-weight-bold"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {userProfile.following === undefined
                              ? 0
                              : userProfile.following.items.length}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-0 text-center">
                          <p className="m-0" style={{ fontSize: "0.8rem" }}>
                            Following
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </Button>
                </Container>
              </Row>
            </Container>
          </Media.Body>
        </Media>
        <Container>
          {!(userProfile.bio === null || userProfile.bio === "") && (
            <Row className="pb-3">
              <small style={{ whiteSpace: "pre-wrap" }}>
                {userProfile.bio}
              </small>
            </Row>
          )}
          {user.id !== guardianId && (
            <Row className="pb-3">
              <Col>
                <Button
                  variant="info"
                  style={{ width: "100%" }}
                  className="py-0"
                  onClick={handleFollow}
                >
                  {isFollowing ? "Following" : "Follow"}
                </Button>
              </Col>
            </Row>
          )}
        </Container>
        {(
          user.id === guardianId &&
          !(
            userProfile.bioAdded &&
            userProfile.profilePicAdded &&
            userProfile.companionAdded &&
            userProfile.postAdded
          )
        ) && (
          <Container className="px-1">
            <Row>
              {(!userProfile.bioAdded || !userProfile.profilePicAdded) && (
                <Col className="px-1 col-6">
                  <Card className="">
                    <Card.Header className="p-2">Edit your profile</Card.Header>
                    <Card.Body className="p-2">
                      {!userProfile.profilePicAdded && (
                        <Link
                          to={`/edit-profile/guardian/${userProfile.id}`}
                          className="text-info d-block pb-1"
                        >
                          <small>Add profile picture</small>
                        </Link>
                      )}
                      {!userProfile.bioAdded && (
                        <Link
                          to={`/edit-profile/guardian/${userProfile.id}`}
                          className="text-info d-block pb-1"
                        >
                          <small>Add bio</small>
                        </Link>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              )}
              {(!userProfile.companionAdded || !userProfile.postAdded) && (
                <Col className="px-1 col-6">
                  <Card className="">
                    <Card.Header className="p-2">Get started</Card.Header>
                    <Card.Body className="py-2 px-1">
                      {!userProfile.companionAdded && (
                        <Link
                          to="/add-companion"
                          className="text-info d-block pb-1"
                        >
                          <small>Add animal companion</small>
                        </Link>
                      )}
                      {!userProfile.postAdded && (
                        <Link
                          to="/create-post"
                          className="text-info d-block pb-1"
                        >
                          <small>Make post</small>
                        </Link>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        )}
        <Container className="pt-3">
          <Row>
            <Col className="p-0">{posts0Component}</Col>
            <Col className="p-0">{posts1Component}</Col>
            <Col className="p-0">{posts2Component}</Col>
          </Row>
        </Container>
      </Container>
      <BottomNavGeneric
        guardianIconFilled={user.id === guardianId ? true : false}
      />
    </>
  );
}

export default GuardianProfile;
