import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function InstructionsMessageRow(props) {
  return (
    <>
      <Row className={props.rowMargin}>
        <Col>
          <p className=" font-weight-light font-italic text-center">
            {props.text}
          </p>
        </Col>
      </Row>
    </>
  );
}

export default InstructionsMessageRow;
