import React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { MdArrowBack, MdSettings,MdSearch } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import { RiSearchEyeLine, RiOpenArmLine } from "react-icons/ri";

function TopNavGeneric(props) {
  const backgroundColor = "bg-white";
  const leftDisabledColor = props.disableLeftIcon ? "text-white" : "text-info";
  const rightDisabledColor = props.disableRightIcon ? "text-white" : "text-info";

  return (
    <>
      <Navbar
        className={`${backgroundColor} px-2 py-0 border-bottom`}
        fixed="top"
      >
        <Nav className="mr-auto">
          {props.addLeftIcon && (
             <Button variant="white" className="p-1" >
            <Link to={props.leftIconPath}>
              {props.leftIcon === "MdArrowBack" && <MdArrowBack size="1.5em" className={leftDisabledColor}/>}
              {props.leftIcon === "MdSettings" && <MdSettings size="1.5em"  className={leftDisabledColor}/>}
              {props.leftIcon === "MdSearch" && <MdSearch size="1.5em"  className={leftDisabledColor}/>}
              {props.leftIcon === "Search" && <RiSearchEyeLine size="1.5em"  className={leftDisabledColor}/>}
            </Link>
                       {props.leftText && 
                        <p className="m-0" style={{fontSize:"0.7rem"}}>{props.leftIcon}</p>
            }
            </Button>
          )}
          {props.disableLeftIcon && (
            <>
              {props.leftIcon === "MdArrowBack" && (
                <MdArrowBack className={leftDisabledColor} size="1.5em" />
              )}
              {props.leftIcon === "MdSettings" && (
                <MdSettings className={leftDisabledColor} size="1.5em" />
              )}
            </>
          )}
        </Nav>
        <Navbar.Brand className="mx-auto">
          {props.title}</Navbar.Brand>
          {/* <Nav className="mx-auto">
          <Container className="">
          <Media>
            <img
              width={"40em"}
              height={"40em"}
              className="mr-2 rounded-circle"
              src={props.profPic}
              //   alt="koh tao animal clinic"
            />
          <Media.Body className="align-self-center">
            <h4>{props.title}</h4>
          </Media.Body>
          </Media>
          </Container>
          
          </Nav> */}
        <Nav className="ml-auto">
          {props.addRightIcon && (
            <Button variant="white" className="p-1" disabled={props.disableRightIcon}>
            <Link to={props.rightIconPath} >
              {props.rightIcon === "MdArrowBack" && (
                <MdArrowBack size="1.5em"  className={rightDisabledColor}/>
              )}
              {props.rightIcon === "MdSettings" && <MdSettings size="1.5em" className={rightDisabledColor}/>}
              {props.rightIcon === "IoMdAddCircle" && <IoMdAddCircle size="1.5em"  className={rightDisabledColor}/>}
              {props.rightIcon === "Profile" && <RiOpenArmLine size="1.5em"  className={rightDisabledColor}/>}
            </Link>
            {props.rightText && 
            <p className="m-0" style={{fontSize:"0.7rem"}}>{props.rightIcon}</p>
}
            </Button>
          )}
          {props.disableRightIcon && (
            <>
              {props.rightIcon === "MdArrowBack" && (
                <MdArrowBack className={rightDisabledColor} size="1.5em" />
              )}
              {props.rightIcon === "MdSettings" && (
                <MdSettings className={rightDisabledColor} size="1.5em" />
              )}
            </>
          )}
        </Nav>
      </Navbar>
    </>
  );
}

export default TopNavGeneric;
