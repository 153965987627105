import React from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import TopNavGeneric from "../../components/Navs/TopNavMain";
import SendMessageNav from "../../components/Navs/BottomNavSendMessage";
import AllMessagesContainer from "../../components/ContainersRows/ContainerAllMessages";

function Chat(props) {
  const { chatId } = useParams();
  const chatName = props.location.name;

  return (
    <>
      <TopNavGeneric
        title={chatName}
        addLeftIcon={true}
        leftIconPath="/conversations"
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="pt-3 pb-5 mb-5">
        <AllMessagesContainer chatId={chatId} />
      </Container>
      <SendMessageNav chatId={chatId} />
    </>
  );
}

export default Chat;
