import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import {
  MdCropLandscape,
  MdCropPortrait,
  MdCropRotate,
  MdAddAPhoto,
  MdArrowForward,
  MdArrowBack,
  MdCheck,
  MdClose
} from "react-icons/md";

function ButtonNav(props) {
  return (
    <>
      <Button onClick={props.onClick} variant="white" className="p-1" disabled={props.isDisabled}>
        {props.text==="Cancel" && <MdClose size="1.5em" className="text-danger" />}
        {props.text==="Back" && <MdArrowBack size="1.5em" className="text-info" />}
        {props.text==="Next" && <MdArrowForward size="1.5em" className="text-info" />}
        {(props.text==="Save"|| props.text==="Post")  && <MdCheck size="1.5em" className="text-success" />}
        {props.text==="Rotate" && <MdCropRotate size="1.5em" />}
        {props.orientation==="Landscape" && <MdCropLandscape size="1.5em" />}
        {props.orientation==="Portrait" && <  MdCropPortrait size="1.5em" />}
        <p className="m-0">
          <small>{props.text}</small>
        </p>
      </Button>
    </>
  );
}

export default ButtonNav;
