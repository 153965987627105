import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import userAuthValidation from "../../hooks/userAuthValidation";

function ChangePasswordHandler() {
  const history = useHistory();

  const [changePasswordObject, setNewPasswordObject] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: ""
  });
  const [error, setError] = useState({
    isError: false,
    message: ""
  });
  const [inputErrors, validation] = userAuthValidation();

  function handleChange(event) {
    const { name, value } = event.target;

    setNewPasswordObject({
      ...changePasswordObject,
      [name]: value
    });

    validation(name, value, changePasswordObject);
  }

  async function handleSubmit() {
    try {
      const user = await Auth.currentAuthenticatedUser();

      console.log("userauth", user);

      await Auth.changePassword(
        user,
        changePasswordObject.oldPassword,
        changePasswordObject.password
      );

      history.push({
        pathname: "/auth-confirmation",
        state: {
          confirmationMessage: "Confirmed. Your password has changed.",
          buttonText: "Back to your profile",
          path: "/guardian/" + user.username
        }
      });
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setError({
        isError: true,
        message: err.message
      });
    }
  }

  return [changePasswordObject, error, inputErrors, handleChange, handleSubmit];
}

export default ChangePasswordHandler;
