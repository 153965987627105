import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import TopNavGeneric from "../../components/Navs/TopNavMain";
import ErrorMessageRow from "../../components/ContainersRows/RowErrorMessage";
import ResendCodeHandler from "../../handlers/AuthHandlers/ResendCodeHandler";
import SubmitButtonContainer from "../../components/ContainersRows/ContainerSubmitButton";
import InstructionsMessageRow from "../../components/ContainersRows/RowInstructionsMessage";

function ResendCode() {
  const [
    user,
    error,
    inputErrors,
    handleChange,
    handleSubmit
  ] = ResendCodeHandler();

  const row_mb = "mb-2";
  const isDisabled = !inputErrors.username.error ? false : true;

  return (
    <>
      <TopNavGeneric
        title="Send new code"
        addLeftIcon={true}
        leftIconPath="/confirm-signup"
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="py-5">
        <InstructionsMessageRow
          rowMargin="pt-5 mb-2"
          text="Please enter your username and we will send you a new verification
              code"
        />
        <Row className={row_mb}>
          <Col>
            <FormControl
              className={inputErrors.username.valid}
              type="text"
              placeholder="Enter username"
              name="username"
              maxLength="50"
              value={user.username}
              onChange={handleChange}
            />
          </Col>
        </Row>
        {error.isError && (
          <ErrorMessageRow rowClass={row_mb} message={error.errorMessage} />
        )}
      </Container>
      <SubmitButtonContainer
        text="Submit"
        rowClass={row_mb}
        handleSubmit={handleSubmit}
        disabled={isDisabled}
      />
    </>
  );
}

export default ResendCode;
