import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import TopNavGeneric from "../../components/Navs/TopNavMain";
import PasswordRequirementsPopover from "../../components/OtherComponents/PopoverPasswordRequirements";
import ErrorMessageRow from "../../components/ContainersRows/RowErrorMessage";
import SignupHandler from "../../handlers/AuthHandlers/SignupHandler";
import SubmitButtonContainer from "../../components/ContainersRows/ContainerSubmitButton";
import { FaInfoCircle } from "react-icons/fa";

function Signup() {
  const [
    newUser,
    error,
    inputErrors,
    handleChange,
    handleSubmit,
  ] = SignupHandler();

  const row_mb = "mb-2";

  const isDisabled =
    !inputErrors.username.error &
    !inputErrors.email.error &
    !inputErrors.password.error &
    !inputErrors.confirmPassword.error
      ? false
      : true;
      const [showPasswordRequirements, setShowPasswordRequirements] = useState(
        false
      );
    
    
      const passwordRequirements = (
        <>
          <small>
            <ul>
              <li className="text-muted">at least than 6 characters</li>
            </ul>
          </small>
        </>
      );
    
      const passwordRequirementsTOUSELATER = (
        <>
          <small>
            <ul>
              <li className="text-muted">between 8 and 100 characters</li>
              <li className="text-muted">1 uppercase letter</li>
              <li className="text-muted">1 lowercase letter</li>
              <li className="text-muted">1 number</li>
              <li className="text-muted">1 special character: !@#$%^&*</li>
            </ul>
          </small>
        </>
      );
  return (
    <>
      <TopNavGeneric
        title="Sign up"
        addLeftIcon={true}
        leftIconPath="/"
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="py-5">
        <Row className="pt-5 mb-2">
          <Col>
            <FormControl
              className={inputErrors.username.valid}
              type="text"
              placeholder="Enter username"
              name="username"
              maxLength="50"
              value={newUser.username}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className={row_mb}>
          <Col>
            <FormControl
              className={inputErrors.email.valid}
              type="text"
              placeholder="Enter email"
              name="email"
              maxLength="50"
              value={newUser.email}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className={row_mb}>
          <Col>
            <FormControl
              className={inputErrors.password.valid}
              type="password"
              placeholder="Password"
              name="password"
              maxLength="100"
              value={newUser.password}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className={row_mb}>
          <Col>
            <FormControl
              className={inputErrors.confirmPassword.valid}
              type="password"
              placeholder="Confirm password"
              name="confirmPassword"
              maxLength="100"
              value={newUser.confirmPassword}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <small className="form-text text-muted d-inline-block">
              Password requirements
            </small>
            <Button
              variant="white d-inline-block p-1"
              className="p-0"
              onClick={() =>
                setShowPasswordRequirements(!showPasswordRequirements)
              }
            >
              <small className="d-flex align-items-start">
                <FaInfoCircle className="" />
              </small>
            </Button>
          </Col>
        </Row>
        {showPasswordRequirements && (
          <Row>
            <Col>{passwordRequirements}</Col>
          </Row>
        )}

        {error.isError && (
          <ErrorMessageRow rowClass={row_mb} message={error.message} />
        )}
      </Container>
      <SubmitButtonContainer
        text="Submit"
        rowClass={row_mb}
        handleSubmit={handleSubmit}
        disabled={isDisabled}
      />
    </>
  );
}

export default Signup;
