import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import userAuthValidation from "../../hooks/userAuthValidation";

function ResendCodeHandler() {
  const [user, setUser] = useState({
    username: ""
  });
  const [error, setError] = useState({
    isError: false,
    message: ""
  });
  const [inputErrors, validation] = userAuthValidation();
  const history = useHistory();

  function handleChange(event) {
    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value
    });
    validation(name, value, user);
  }

  async function handleSubmit() {
    try {
      await Auth.resendSignUp(user.username);
      history.push({
        pathname: "/auth-confirmation",
        state: {
          confirmationMessage: "New code sent. Check your email",
          buttonText: "Confirm sign up",
          path: "/confirm-signup"
        }
      });
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setError({
        isError: true,
        errorMessage: err.message
      });
    }
  }

  return [user, error, inputErrors, handleChange, handleSubmit];
}

export default ResendCodeHandler;
