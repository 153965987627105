import React, { useContext, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-bootstrap/Media";
import { Link, useParams } from "react-router-dom";
import TopNavGeneric from "../components/Navs/TopNavMain";
import BottomNavGeneric from "../components/Navs/BottomNavMain";
// import { Context } from "../Context";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  getUser as GetUser,
  getPost as GetPost,
  searchUsers as SearchUsers,
  searchCompanions as SearchCompanions,
} from "../graphql/queries";
import { useHistory } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { Context } from "../Context";

function Search() {
  const { user } = useContext(Context);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMsg, setNoResultsMsg] = useState("");
  const staticImg =
    "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";

  const [guardianHighlighted, setGuardianHighlighted] = useState(true);

  const variantGuardian = guardianHighlighted ? "info" : "outline-info";
  const variantCompanion = guardianHighlighted ? "outline-info" : "info";

  function handleSearchSwitch() {
    setGuardianHighlighted(!guardianHighlighted);
    setSearchResults([]);
    setSearchValue("");
    setNoResultsMsg("");
  }

  useEffect(() => {}, []);

  async function handleSearch(e) {
    const targetValue = e.target.value;
    console.log("tagetValue", targetValue);
    setSearchValue(e.target.value);

    let searchInfo = "";
    if (guardianHighlighted) {
      const input = {
        username: {
          matchPhrasePrefix: targetValue,
        },
      };

      const searchResponse = await API.graphql(
        graphqlOperation(SearchUsers, {
          filter: input,
          limit: 5,
          sort: { direction: "desc" },
        })
      );

      searchInfo = searchResponse.data.searchUsers.items;

      console.log("searchInfo", searchInfo);
    } else {
      console.log("here", targetValue);

      const input = {
        name: {
          matchPhrasePrefix: targetValue,
        },
      };

      const searchResponse = await API.graphql(
        graphqlOperation(SearchCompanions, {
          filter: input,
          limit: 5,
          sort: { direction: "desc" },
        })
      );

      console.log("sr:", searchResponse);

      searchInfo = searchResponse.data.searchCompanions.items;

      console.log("searchInfo", searchInfo);
    }
    let profPic = "";

    if (searchInfo.length > 0) {
      const searchObj = searchInfo
        .filter((info) => {
          if (info.id === user.id) {
            console.log("info", info);
            return false;
          }
          return true;
        })
        .map(async (info) => {

          if (info.profilePicIcon === null) {
            profPic = staticImg;
          } else {
            const key = info.profilePicIcon.key;
            profPic = await Storage.get(key);
          }
          return { ...info, profPic };
        });

      Promise.all(searchObj).then((results) => {
        //   console.log("results", results);
        setSearchResults(results);
        //   return results
      });
      if (searchObj.length === 0) {
        setNoResultsMsg("No matches found");
      }
    } else {
      setSearchResults([]);
      if (targetValue === "") {
        setNoResultsMsg("");
      } else {
        setNoResultsMsg("No matches found");
      }
    }
  }

  console.log("searchResults", searchResults);

  let searchResultsComponent = searchResults.map((result) => {
    console.log("result", result);
    return (
      <Media key={result.id} className="border-bottom py-1">
        <img
          width={"40rem"}
          height={"40rem"}
          className="mr-3 rounded-circle"
          src={result.profPic}
        />
        <Media.Body>
          <Link
            to={
              guardianHighlighted
                ? `/guardian/` + result.id
                : `/companion/` + result.id
            }
          >
            <Container className="px-0">
              <Row className="ml-auto mr-auto">
                <Col className="col-10">
                  <h6 className="d-inline text-dark">
                    {guardianHighlighted ? result.username : result.name}
                  </h6>
                </Col>

                <Col className="col-2 text-right">
                  <IoIosArrowForward
                    size="1.5em"
                    className="text-info text-right"
                  />
                </Col>
              </Row>
            </Container>
          </Link>
        </Media.Body>
      </Media>
    );
  });

  console.log(guardianHighlighted);

  return (
    <>
      <TopNavGeneric
        title={"Search"}
        addLeftIcon={true}
        leftIconPath={"/posts"}
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="py-4">
        <Row className="pt-5 mb-2">
          <Col>
            <Button
              className="d-inline mr-2"
              variant={variantGuardian}
              onClick={handleSearchSwitch}
            >
              Guardian
            </Button>
            <Button
              className="d-inline"
              variant={variantCompanion}
              onClick={handleSearchSwitch}
            >
              Companion
            </Button>
          </Col>
        </Row>
        <Row className="">
          <Col className="">
            <FormControl
              className=""
              type="text"
              maxLength="100"
              placeholder="Search..."
              name="search"
              value={searchValue}
              onChange={handleSearch}
              required
            />
          </Col>
        </Row>
        <Row className="pt-3">
          <Col>
            {searchResults.length > 0 ? (
              searchResultsComponent
            ) : (
              <p>{noResultsMsg}</p>
            )}
          </Col>
        </Row>
      </Container>
      <BottomNavGeneric />
    </>
  );
}

export default Search;
