import React, { useState, useCallback, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopNavGeneric from "../components/Navs/TopNavMain";
import BottomNavGeneric from "../components/Navs/BottomNavMain";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-easy-crop";
import "../index.css";
import { postsByPostType as PostsByPostType } from "../graphql/queries";
import { Storage, API, graphqlOperation } from "aws-amplify";
import PageLoader from "../components/OtherComponents/LoaderPage";
import {Context} from "../Context"


function Posts() {

    const [posts, setPosts] = useState([])
    const [loading, setLoading] = useState(true);
    const {user} = useContext(Context)

  useEffect(() => {
    setLoading(true);
    async function getPosts() {
      try {
        const getPostsByPostTypeResponse = await API.graphql(
          graphqlOperation(PostsByPostType, {
            postType: "public",
            sortDirection: "DESC",
          })
        );
        // setUserProfile(getUser);
        console.log("getPostsByPostTypeResponse", getPostsByPostTypeResponse);

        const postInfo = getPostsByPostTypeResponse.data.postsByPostType.items;

        console.log(postInfo);

        let postPic = "";
        if (postInfo.length > 0) {
          const postObj = postInfo.map(async (post) => {
            const key = post.postImg.key;
            postPic = await Storage.get(key);
            return { ...post, postPic };
          });

          Promise.all(postObj).then((results) => {
            //   console.log("results", results);
            setPosts(results);
            //   return results
          });

        }
      } catch (err) {
        console.log("error:", err);
      }
    }
    getPosts();

    const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
  }, []);

  const width = "100%";
  const height = "100%";

  let posts0Component = posts
  .filter((post, index) => {
    return index % 3 === 0;
  })
  .map((post) => {
    return (
      <div key={post.id} className="pb-1 pr-1">
        <img width={width} height={height} className="" src={post.postPic} />
      </div>
    );
  });

let posts1Component = posts
  .filter((post, index) => {
    return (index - 1) % 3 === 0;
  })
  .map((post) => {
    return (
      <div key={post.id} className="pb-1 pr-1">
        <img width={width} height={height} className="" src={post.postPic} />
      </div>
    );
  });

let posts2Component = posts
  .filter((post, index) => {
    return (index + 1) % 3 === 0;
  })
  .map((post) => {
    return (
      <div key={post.id} className="pb-1">
        <img width={width} height={height} className="" src={post.postPic} />
      </div>
    );
  });

  console.log('posts are here', posts)

  const constainerShow = loading ? "mt-5 pt-3 mb-5 pb-5 px-1 d-none" : "mt-5 pt-3 mb-5 pb-5 px-1";

  return (
    <>
      <TopNavGeneric
        title="Beaukoh"
        addLeftIcon={true}
        leftIconPath="/search"
        leftIcon="Search"
        leftText={true}
        addRightIcon={true}
        disableRightIcon={true}
        rightIcon="Profile"
        rightIconPath={"#"}
      />
      <PageLoader loading={loading} />
              <Container className={constainerShow}>
                  {/* <Container> */}
          <Row className="ml-auto mr-auto">
            <Col className="p-0">{posts0Component}</Col>
            <Col className="p-0">{posts1Component}</Col>
            <Col className="p-0">{posts2Component}</Col>
          </Row>
          {/* </Container> */}
        </Container>
      {/* <Container className="mt-5">
        <Row className="mb-2">
          <Col className="text-center">
            <p className="">
              Welcome to Beaukoh! We are a community of people working to help
              and support all animals.
            </p>
          </Col>
        </Row>
      </Container> */}
      <BottomNavGeneric postsIconFilled={true} />
    </>
  );
}

export default Posts;
