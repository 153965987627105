import React, {useState, useContext} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import TopNavGeneric from "../../components/Navs/TopNavMain";
import PasswordRequirementsPopover from "../../components/OtherComponents/PopoverPasswordRequirements";
import ErrorMessageRow from "../../components/ContainersRows/RowErrorMessage";
import ChangePasswordHandler from "../../handlers/AuthHandlers/ChangePasswordHandler";
import SubmitButtonContainer from "../../components/ContainersRows/ContainerSubmitButton";
import { FaInfoCircle } from "react-icons/fa";
import {Context} from "../../Context"

function ChangePassword() {
  const [
    changePasswordObject,
    error,
    inputErrors,
    handleChange,
    handleSubmit
  ] = ChangePasswordHandler();

  const {user} = useContext(Context)

  const [showPasswordRequirements, setShowPasswordRequirements] = useState(
    false
  );

  const passwordRequirements = (
    <>
      <small>
        <ul>
          <li className="text-muted">at least than 6 characters</li>
        </ul>
      </small>
    </>
  );

  const passwordRequirementsTOUSELATER = (
    <>
      <small>
        <ul>
          <li className="text-muted">between 8 and 100 characters</li>
          <li className="text-muted">1 uppercase letter</li>
          <li className="text-muted">1 lowercase letter</li>
          <li className="text-muted">1 number</li>
          <li className="text-muted">1 special character: !@#$%^&*</li>
        </ul>
      </small>
    </>
  );


  const row_mb = "mb-2";

  const isDisabled =
    !inputErrors.oldPassword.error &
    !inputErrors.sameOldNewPassword.error &
    !inputErrors.password.error &
    !inputErrors.confirmPassword.error
      ? false
      : true;

  const oldPasswordValid =
    changePasswordObject.oldPassword === ""
      ? ""
      : inputErrors.oldPassword.error || inputErrors.sameOldNewPassword.error
      ? "is-invalid"
      : "is-valid";
  const passwordValid =
    changePasswordObject.password === ""
      ? ""
      : inputErrors.password.error || inputErrors.sameOldNewPassword.error
      ? "is-invalid"
      : "is-valid";

  return (
    <>
      <TopNavGeneric
        title="Change password"
        addLeftIcon={true}
        leftIconPath={`/settings/guardian/${user.id}`}
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="py-5">
        <Row className="pt-3 mb-2">
          <Col>
            <FormControl
              className={oldPasswordValid}
              type="password"
              placeholder="Old password"
              name="oldPassword"
              maxLength="100"
              value={changePasswordObject.oldPassword}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className={row_mb}>
          <Col>
            <FormControl
              className={passwordValid}
              type="password"
              placeholder="New password"
              name="password"
              maxLength="100"
              value={changePasswordObject.password}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className={row_mb}>
          <Col>
            <FormControl
              className={inputErrors.confirmPassword.valid}
              type="password"
              placeholder="Confirm password"
              name="confirmPassword"
              maxLength="100"
              value={changePasswordObject.confirmPassword}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <small className="form-text text-muted d-inline-block">
              Password requirements
            </small>
            <Button
              variant="white d-inline-block p-1"
              className="p-0"
              onClick={() =>
                setShowPasswordRequirements(!showPasswordRequirements)
              }
            >
              <small className="d-flex align-items-start">
                <FaInfoCircle className="" />
              </small>
            </Button>
          </Col>
        </Row>
        {showPasswordRequirements && (
          <Row>
            <Col>{passwordRequirements}</Col>
          </Row>
        )}
        {error.isError && (
          <ErrorMessageRow rowClass={row_mb} message={error.message} />
        )}
      </Container>
      <SubmitButtonContainer
        text="Submit"
        rowClass={row_mb}
        handleSubmit={handleSubmit}
        disabled={isDisabled}
      />
    </>
  );
}

export default ChangePassword;
