import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import TopNavGeneric from "../components/Navs/TopNavMain";
import LinkComponent from "../components/ButtonsLinks/LinkComponent";
import SignoutHandler from "../handlers/AuthHandlers/SignoutHandler";
import SubmitButtonContainer from "../components/ContainersRows/ContainerSubmitButton";
import {
  createRequest as CreateRequest,
  deleteRequest as DeleteRequest,
  createGuardianshipLink as CreateGuardianshipLink,
  deleteGuardianshipLink as DeleteGuardianshipLink
} from "../graphql/mutations";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { getCompanion as GetCompanion } from "../graphql/queries";

function Settings() {
  const [user, handleSignout] = SignoutHandler();
  const { userType, id } = useParams();
  const [companion, setCompanion] = useState()
  const [guardianshipLinkId, setGuardianshipLinkId] = useState(null)
  const history = useHistory();

  useEffect(()=> {
    async function getCompanionDetails(id) {
      try {
        const {
          data: { getCompanion },
        } = await API.graphql(graphqlOperation(GetCompanion, { id: id }));
        console.log(getCompanion);
        setCompanion(getCompanion);

        const guardianInfo = getCompanion.guardians.items

        const guardianshipId = guardianInfo.filter(guardianship => {
          return guardianship.guardianshipLinkGuardianId === user.id
        }).map(guardianship => {
          return guardianship.id
        })

        if (guardianshipId[0]===undefined) {
          history.push({
            pathname: `/guardian/${user.id}`,
            // state: {
            //   confirmationMessage: `Thanks ${newUser.username} for signing up! Check your email to verify your account. Once verified head back and log in.`
            // }
          });

        } else {

        }
        setGuardianshipLinkId(guardianshipId[0])

      } catch(err) {
        console.log('error:', err)
      }
    }

    if (userType==='companion') {
      getCompanionDetails(id);
    } else if (id !== user.id) {
      history.push({
        pathname: `/guardian/${user.id}`,
        // state: {
        //   confirmationMessage: `Thanks ${newUser.username} for signing up! Check your email to verify your account. Once verified head back and log in.`
        // }
      });

    }


    


  }, [])

  console.log(userType, id);
  console.log(guardianshipLinkId)

  async function removeGuardian() {
    try {
      const deleteGuardianLinkResponse = await API.graphql(
        graphqlOperation(DeleteGuardianshipLink, {
          input: {
            id: guardianshipLinkId
          },
        })
      );

      console.log("deleteGuardianLinkResponse", deleteGuardianLinkResponse);

      history.push({
        pathname: `/companion/${id}`,
        // state: {
        //   confirmationMessage: `Thanks ${newUser.username} for signing up! Check your email to verify your account. Once verified head back and log in.`
        // }
      });

    } catch (err) {
      console.log("error:", err);
    }
  }



  return (
    <>
      <TopNavGeneric
        title="Settings"
        addLeftIcon={true}
        leftIconPath={"/" + userType + "/" + id}
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="pt-5 mb-5">
        <Row className="pt-3">
          <Col>
            <LinkComponent
              name="Edit profile"
              path={"/edit-profile/" + userType + "/" + id}
            />
          </Col>
        </Row>

        {userType === "guardian" ? (
          <Row>
            <Col>
              <LinkComponent name="Change password" path="/change-password" />
            </Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col>
                <LinkComponent
                  name="Add guardians"
                  path={`/add-guardian/${id}`}
                />
              </Col>
            </Row>
            <Row className="py-3">
              <Col className="text-center">
                <Button onClick={removeGuardian}>
                  Remove yourself as guardian
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>

      {userType === "guardian" && (
        <SubmitButtonContainer text="Sign out" handleSubmit={handleSignout} />
      )}
    </>
  );
}

export default Settings;
