/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:8624c18a-b2fa-4700-9895-90b302f7351a",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_IzSTuND64",
    "aws_user_pools_web_client_id": "44uri4b3ihcp6g662qe089bu98",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://csuiulne2bfmnpgdkx6gxbgha4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "beaukohtao-content91826-kohenv",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
