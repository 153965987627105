import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import ButtonNav from "../ButtonsLinks/ButtonNav";
import AddImageButton from "../ButtonsLinks/ButtonAddImage";

function BottomNavUpload(props) {
  return (
    <>
      <Navbar className="bg-white border-top" fixed="bottom">
        <Nav className="mr-auto">
          <ButtonNav
            text="Rotate"
            isDisabled={props.isDisabled}
            onClick={props.handleRotation}
          />
        </Nav>
        {!props.removeOrientationBtn && (
          <Nav className="mx-auto">
            {props.orientBtn === "Landscape" ? (
              <ButtonNav
                text="Orient"
                orientation="Landscape"
                isDisabled={props.isDisabled}
                onClick={props.handleDimension}
              />
            ) : (
              <ButtonNav
                text="Orient"
                orientation="Portrait"
                isDisabled={props.isDisabled}
                onClick={props.handleDimension}
              />
            )}
          </Nav>
        )}
        <Nav className="ml-auto">
          <AddImageButton
            text={props.addPhotoBtnText}
            isDisabled={props.isAddPhotoDisabled}
            handleChange={props.handleChange}
          />
        </Nav>
      </Navbar>
    </>
  );
}

export default BottomNavUpload;
