import React from "react";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import {
  MdChatBubbleOutline,
  MdChatBubble,
  MdArrowBack,
  MdClose,
  MdPets,
  MdPerson,
  MdPersonOutline,
  MdAddBox,
  MdSearch
} from "react-icons/md";
import {
  RiAddBoxLine,
  RiImageAddLine,
  RiChatSmile3Line,
  RiNotification2Line,
  RiUser5Line,
  RiMickeyLine,
  RiOpenArmLine,
  RiDashboardLine
} from "react-icons/ri";

import { FaQuestionCircle, FaRegQuestionCircle, FaBell } from "react-icons/fa";
import { GoDiffAdded } from "react-icons/go";
import { IoMdPaw } from "react-icons/io";
import { GiIsland } from "react-icons/gi";
import "../../css/home.css"

function LinkNav(props) {
  const iconSize = "1.5em"
  return (
    <>
      <Link to={props.to} className="">
        <Button variant="white" className="p-1">

          {props.text === "Cancel" && (
            <MdClose size={iconSize} className="text-danger" />
          )}
          {props.text === "Back" && (
            <MdArrowBack size={iconSize} className="text-info" />
          )}
          {props.text === "Search" && (
            <MdSearch
              size={iconSize}
              className={props.filled ? "text-secondary" : "text-info"}
            />
          )}
          {props.text === "Explore" && (
            <GiIsland
              size={iconSize}
              className={props.filled ? "text-secondary" : "text-info"}
            />
          )}
          {props.text === "Posts" && (
            <RiDashboardLine
              size={iconSize}
              className={props.filled ? "text-secondary" : "text-info"}
            />
          )}
          {props.text === "Alerts" && (
            <RiNotification2Line
              size={iconSize}
              className={props.filled ? "text-secondary" : "text-info"}
            />
          )}
          {props.text === "Local" && (
            <FaQuestionCircle
              size={iconSize}
              className={props.filled ? "text-secondary" : "text-info"}
            />
          )}
          {props.text === "Chat" && (
            <RiChatSmile3Line
              size={iconSize}
              className={props.filled ? "text-secondary" : "text-info"}
            />
          )}
          {props.text === "Post" && (
            <RiImageAddLine size={iconSize} className="text-info" />
          )}
          {props.text === "Pals" && (
            <RiMickeyLine
              size={iconSize}
              className={props.filled ? "text-secondary" : "text-info"}
            />
          )}
          {props.text === "You" && (
            <RiOpenArmLine
              size={iconSize}
              className={props.filled ? "text-secondary" : "text-info"}
            />
          )}
          <p className="m-0" style={{fontSize:"0.7rem"}}>{props.text}</p>
        </Button>
      </Link>
    </>
  );
}

export default LinkNav;
