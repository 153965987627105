import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import userAuthValidation from "../../hooks/userAuthValidation";

function ForgotPasswordHandler() {
  const [email, setEmail] = useState({
    email: ""
  });
  const [error, setError] = useState({
    isError: false,
    errorMessage: ""
  });
  const [inputErrors, validation] = userAuthValidation();
  const history = useHistory();

  function handleChange(event) {
    const { name, value } = event.target;
    setEmail({
      ...email,
      [name]: value
    });
    validation(name, value, email);
  }

  async function handleSubmit() {
    try {
      await Auth.forgotPassword(email.email);
      history.push("/set-new-password");
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setError({
        isError: true,
        errorMessage: "Email not found. Please try a different one."
      });
    }
  }

  return [email, error, inputErrors, handleChange, handleSubmit];
}

export default ForgotPasswordHandler;
