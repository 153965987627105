import React from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

function HomeTopNav() {
  return (
    <>
      <Navbar className="bg-transparent" sticky="top">
        <Nav className="mr-auto ">
          <Link to="/about" className="text-info font-weight-bold">
            About
          </Link>
        </Nav>
        <Nav className="ml-auto">
          <Link to="/emergency" className="text-danger font-weight-bold">
            Emergency?
          </Link>
        </Nav>
      </Navbar>
    </>
  );
}

export default HomeTopNav;
