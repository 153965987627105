import React, { useState, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import TopNavGeneric from "../../components/Navs/TopNavMain";
import ErrorMessageRow from "../../components/ContainersRows/RowErrorMessage";
import LoginHandler from "../../handlers/AuthHandlers/LoginHandler";
import SubmitButtonContainer from "../../components/ContainersRows/ContainerSubmitButton";
import PageLinkRow from "../../components/ButtonsLinks/PageLinkRow";
import PasswordRequirementsPopover from "../../components/OtherComponents/PopoverPasswordRequirements";
import { FaInfoCircle } from "react-icons/fa";
// import useAsync from "../../hooks/useAsync";
import { Context } from "../../Context";

function Login() {
  const [
    userLogin,
    error,
    inputErrors,
    handleChange,
    handleSubmit
  ] = LoginHandler();

  const { updateAuthenticatedUser, setUser } = useContext(Context);

  const [showPasswordRequirements, setShowPasswordRequirements] = useState(
    false
  );

  const row_mb = "mb-2";

  const passwordRequirements = (
    <>
      <small>
        <ul>
          <li className="text-muted">at least than 6 characters</li>
        </ul>
      </small>
    </>
  );

  const passwordRequirementsTOUSELATER = (
    <>
      <small>
        <ul>
          <li className="text-muted">between 8 and 100 characters</li>
          <li className="text-muted">1 uppercase letter</li>
          <li className="text-muted">1 lowercase letter</li>
          <li className="text-muted">1 number</li>
          <li className="text-muted">1 special character: !@#$%^&*</li>
        </ul>
      </small>
    </>
  );

  const isDisabled =
    !inputErrors.username.error & !inputErrors.password.error ? false : true;

  return (
    <>
      <TopNavGeneric
        title="Log in"
        addLeftIcon={true}
        leftIconPath="/"
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="py-5 ">
        <Row className="pt-4 mb-2">
          <Col>
            <FormControl
              className=""
              type="text"
              maxLength="50"
              placeholder="Enter username or email"
              name="username"
              value={userLogin.username}
              onChange={handleChange}
              required
            />
          </Col>
        </Row>
        <Row className={row_mb}>
          <Col>
            <FormControl
              className=""
              type="password"
              maxLength="100"
              placeholder="Password"
              name="password"
              value={userLogin.password}
              onChange={handleChange}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <small className="form-text text-muted d-inline-block">
              Password requirements
            </small>
            <Button
              variant="white d-inline-block p-1"
              className="p-0"
              onClick={() =>
                setShowPasswordRequirements(!showPasswordRequirements)
              }
            >
              <small className="d-flex align-items-start">
                <FaInfoCircle className="" />
              </small>
            </Button>
          </Col>
        </Row>
        {showPasswordRequirements && (
          <Row>
            <Col>{passwordRequirements}</Col>
          </Row>
        )}
        <PageLinkRow
          text="Forgot password?"
          linkTo="/forgot-password"
          rowClass="py-2"
        />
        {error.isError && (
          <ErrorMessageRow
            rowClass={`${row_mb}`}
            message={error.errorMessage}
          />
        )}
      </Container>
      {/* <Container>
      <Row className={row_mb}>
        <Col className="text-center">
          <Button className="px-5" onClick={useAsync(handleSubmit,updateAuthenticatedUser )} disabled={isDisabled}>
            Submit
          </Button>
        </Col>
      </Row>
      </Container> */}
      <SubmitButtonContainer
        text="Submit"
        rowClass={row_mb}
        handleSubmit={handleSubmit}
        disabled={isDisabled}
      />
    </>
  );
}

export default Login;
