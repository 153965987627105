import React, { useState, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopNavGeneric from "../components/Navs/TopNavMain";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Cropper from "react-easy-crop";
import "../index.css";

function About() {

  return (
    <>
      <TopNavGeneric
        title="About"
        addLeftIcon={true}
        leftIconPath="/"
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="py-5">
        <Row className="pt-3">
          <Col className="text-center">
            <p className="">
              Welcome to Beaukoh! We are a community of people working to help
              and support all animals.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default About;
