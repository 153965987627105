import React, { useEffect, useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import TopNavGeneric from "../components/Navs/TopNavMain";
import BottomNavGeneric from "../components/Navs/BottomNavMain";
import Media from "react-bootstrap/Media";
import { Context } from "../Context";
import {
  // updateUser as UpdateUser,
  getNotificationsDetail as GetNotificationsDetail,
  getRequestDetail as GetRequestDetail,
} from "../graphql/customQueries";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { IoIosArrowForward } from "react-icons/io";
import blackdog from "../static/blackdog.jpg";
import genericGuardianProfPic from "../static/generic_guardian_profile.png";
import {
  createRequest as CreateRequest,
  deleteRequest as DeleteRequest,
  createGuardianshipLink as CreateGuardianshipLink,
  deleteGuardianshipLink as DeleteGuardianshipLink,
} from "../graphql/mutations";
import { GiConsoleController } from "react-icons/gi";
import { FormattedDateParts } from "react-intl";
import PageLoader from "../components/OtherComponents/LoaderPage";

function Notifications() {
  const { user } = useContext(Context);
  const [notifications, setNotifications] = useState([]);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    async function getNotifications(userId) {
      try {
        const notificationsResponse = await API.graphql(
          graphqlOperation(GetNotificationsDetail, {
            id: userId,
            limit: 10,
            //   nextToken: fetchDetail.nextToken
          })
        );

        console.log("notifications", notificationsResponse);

        const notificationsInfo =
          notificationsResponse.data.getUser.notifications.items;

        //   console.log('notificationsInfo', notificationsInfo)

        const notificationsObj = notificationsInfo.map(async (notification) => {
          // console.log('notification',notification);
          // const key = notification.createdBy.profilePicIcon.key;
          let profPic = "";
          if (notification.createdBy.profilePicIcon === null) {
            profPic = genericGuardianProfPic;
          } else {
            const key = notification.createdBy.profilePicIcon.key;
            profPic = await Storage.get(key);
          }
          // console.log(key, profPic)
          //   console.log({ ...companion, profPic });
          return { ...notification, profPic };
        });

        Promise.all(notificationsObj).then((results) => {
          //   console.log("results", results);
          setNotifications(results);
          //   return results
        });
      } catch (err) {
        console.log("error: ", err);
      }
    }

    async function getRequests(userId) {
      try {
        const requestsResponse = await API.graphql(
          graphqlOperation(GetRequestDetail, {
            id: userId,
            //   nextToken: fetchDetail.nextToken
          })
        );

        console.log("requests", requestsResponse);

        const requestsInfo = requestsResponse.data.getUser.requests.items;

        console.log("requestsInfo", requestsInfo);

        const requestsObj = requestsInfo.map(async (request) => {
          // console.log('notification',notification);

          let companionProfPic = "";
          if (request.companion.profilePicIcon === null) {
            companionProfPic = blackdog;
          } else {
            const key = request.companion.profilePicIcon.key;
            companionProfPic = await Storage.get(key);
          }
          return { ...request, companionProfPic };
        });

        Promise.all(requestsObj).then((results) => {
          setRequests(results);
        });
      } catch (err) {
        console.log("error: ", err);
      }
    }

    getRequests(user.id);
    getNotifications(user.id);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  let notificationsComponent = notifications.map((notification) => {
    return (
      <Media key={notification.id} className="border-bottom py-1">
        <img
          width={"40rem"}
          height={"40rem"}
          className="mr-3 rounded-circle"
          src={notification.profPic}
        />
        <Media.Body>
          <Link
            to={`/guardian/` + notification.createdBy.username}
            className=""
          >
            <Container className="px-0">
              <Row className="ml-auto mr-auto">
                <Col className="px-0">
                  <small className="m-0 text-dark d-inline-block">
                    <b>{notification.createdBy.username}</b> {notification.type}
                    ed you
                  </small>
                </Col>
              </Row>
              <Row className="ml-auto mr-auto">
                <Col className="px-0 ">
                  <FormattedDateParts
                    className=""
                    value={new Date(notification.createdAt)}
                    month="numeric"
                    year="numeric"
                    day="2-digit"
                    hour="numeric"
                    minute="numeric"
                    hour12={false}
                  >
                    {(parts) => (
                      <>
                        <small className="text-dark font-italic">
                          {parts[4].value.slice(0)}-{parts[0].value}-
                          {parts[2].value}
                          {" at "}
                          {parts[6].value}:{parts[8].value}
                        </small>
                      </>
                    )}
                  </FormattedDateParts>
                </Col>
              </Row>
            </Container>
          </Link>
        </Media.Body>
      </Media>
    );
  });

  let requestsComponent = requests.map((request) => {
    return (
      <Media key={request.id}>
        <img
          width={"40rem"}
          height={"40rem"}
          className="mr-3 rounded-circle"
          src={request.companionProfPic}
        />
        <Media.Body>
          <Link to={`/companion/` + request.companion.id}>
            <Container className="px-0">
              <Row className="ml-auto mr-auto">
                <Col className="px-0">
                  <small className="m-0 text-dark d-inline-block">
                    <b>{request.createdBy.username}</b> added you as a guardian
                    for <b>{request.companion.name}</b> |{" "}
                    <FormattedDateParts
                      className=""
                      value={new Date(request.createdAt)}
                      month="numeric"
                      year="numeric"
                      day="2-digit"
                      hour="numeric"
                      minute="numeric"
                      hour12={false}
                    >
                      {(parts) => (
                        <>
                          <p className="text-dark font-italic d-inline-block m-0">
                            {parts[4].value.slice(0)}-{parts[0].value}-
                            {parts[2].value}
                            {" at "}
                            {parts[6].value}:{parts[8].value}
                          </p>
                        </>
                      )}
                    </FormattedDateParts>
                  </small>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    variant="secondary"
                    className="d-inline-block mr-4 py-1 btn-sm"
                    onClick={() =>
                      addGuardian(request.companion.id, request.id)
                    }
                  >
                    Accept
                  </Button>
                  <Button
                    variant="info"
                    className="d-inline-block py-1 btn-sm"
                    onClick={() => removeRequestAddGuardian(request.id)}
                  >
                    Decline
                  </Button>
                </Col>
              </Row>
            </Container>
          </Link>
        </Media.Body>
      </Media>
    );
  });

  async function removeRequestAddGuardian(requestId) {
    try {
      const deleteRequestResponse = await API.graphql(
        graphqlOperation(DeleteRequest, {
          input: { id: requestId },
        })
      );
      console.log("deleteRequestResponse", deleteRequestResponse);
    } catch (err) {
      console.log("error: ", err);
    }
  }

  async function addGuardian(companionId, requestId) {
    console.log(user.id, companionId);
    try {
      const createGuardianLinkResponse = await API.graphql(
        graphqlOperation(CreateGuardianshipLink, {
          input: {
            guardianshipLinkGuardianId: user.id,
            guardianshipLinkCompanionId: companionId,
          },
        })
      );

      console.log("createGuardianLink", createGuardianLinkResponse);

      const deleteRequestResponse = await API.graphql(
        graphqlOperation(DeleteRequest, {
          input: { id: requestId },
        })
      );
      console.log("deleteRequestResponse", deleteRequestResponse);
    } catch (err) {
      console.log("error:", err);
    }
  }

  console.log("notifications", notifications);

  const constainerShow = loading ? "py-5 d-none" : "py-5";

  return (
    <>
      <TopNavGeneric title="Alerts" />
      <PageLoader loading={loading} />
      <Container className={constainerShow}>
        {requests.length > 0 && (
          <>
            <Row>
              <Col>
                <h6>Requests</h6>
              </Col>
            </Row>
            <Row>
              <Col>{requestsComponent}</Col>
            </Row>
          </>
        )}
        {notifications.length > 0 ? (
          <>
            <Row className="">
              <Col>
                <h6>Notifications</h6>
              </Col>
            </Row>
            <Row>
              <Col>{notificationsComponent}</Col>
            </Row>
          </>
        ) : (
          <Row className="">
            <Col>
              <h6>No notifications</h6>
            </Col>
          </Row>
        )}
      </Container>
      <BottomNavGeneric notificationsIconFilled={true} />
    </>
  );
}

export default Notifications;
