import React, { useState, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import NavTop from "../components/Navs/TopNavUpload";
import PageLoader from "../components/OtherComponents/LoaderPage";
import TextContainer from "../components/ContainersRows/ContainerText";
import NavBottom from "../components/Navs/BottomNavUpload";
import PostCaptionContainer from "../components/ContainersRows/ContainerPostCaption";
import CropImageHandler from "../handlers/ImageHandlers/CropImageHandler";
import Cropper from "react-easy-crop";
import "../css/crop.css";
import { Context } from "../Context";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { getUserCompanionInfo as GetUserCompanionInfo } from "../graphql/customQueries";
import ReactPlayer from "react-player";
import ChangePassword from "./Auth/ChangePassword";
import imageCompression from "browser-image-compression";

function CreatePost() {
  const isProfile = false;
  const isCompanion = false;

  const { user } = useContext(Context);
  const [widthAspect, setWidthAspect] = useState(3);
  const [heightAspect, setHeightAspect] = useState(4);
  const [orientBtnText, setOrientBtnText] = useState("Portrait");
  const [page, setPage] = useState(1);
  const [userCompanions, setUserCompanions] = useState([]);
  const [values, setValues] = useState([]);
  const userType = "guardian";
  const userId = user.id;
  const [posterImg, setPosterImg] = useState(null)

  const [
    handleChange,
    handleRotation,
    crop,
    setCrop,
    loading,
    zoom,
    file,
    setZoom,
    uploadFileS3,
    onCropComplete,
    mediaSrc,
    mediaType,
    rotation,
    croppedImg,
    getCroppedFile,
    captionBio,
    setCaptionBio,
  ] = CropImageHandler(isProfile, isCompanion, userType, userId, values);

  useEffect(() => {
    async function getUserCompanions(id) {
      try {
        const userCompanionsResponse = await API.graphql(
          graphqlOperation(GetUserCompanionInfo, { id: id })
        );

        const companionsInfo =
          userCompanionsResponse.data.getUser.companions.items;

        const companionsObj = companionsInfo.map(async ({ companion }) => {
          const key = companion.profilePicIcon.key;
          const profPic = await Storage.get(key);
          return { ...companion, profPic };
        });

        Promise.all(companionsObj).then((results) => {
          setUserCompanions(results);
        });
      } catch (e) {
        console.log("error", e);
      }
    }

    getUserCompanions(user.id);

    const timer = setTimeout(() => {}, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  async function createVideoImg() {
    // console.log('mediaSrc', mediaSrc)

    try {
      // console.log('i')
      // const image = await createImage(mediaSrc);
      // console.log('i', image)

    const canvas = document.getElementById('canvas');
    // const canvas = document.createElement("canvas");
    // const video = document.getElementById("video");
    const videoPlayer = document.getElementById("videoPlayer").getElementsByTagName('video')[0];

    // console.log('video', video)
    console.log('videoPlayer', videoPlayer)

    // video.src = mediaSrc

    // height and width of div
    const cw = videoPlayer.videoWidth
    const ch = videoPlayer.videoHeight

    // console.log('video', video)

    console.log("video.videoWidth", cw);
    console.log('video.videoHeight', ch)
    
    canvas.width = cw;
    canvas.height = ch;
    console.log('canvas', canvas)
    // canvas.setAttribute('height', video.videoHeight);
    canvas.getContext("2d").drawImage(videoPlayer, 0, 0, cw, ch);

     return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });


    } catch (err) {
      console.log('error', err)
    }
  }

async function getBlob() {
  try {

    const canvasImg = await createVideoImg();

    console.log('canvasImg', canvasImg)

    let blob = await fetch(canvasImg).then((r) => r.blob());
    console.log('blob', blob)
    // console.log('file.name', file.name.split('.')[0])
    const fileName = file.name.split('.')[0]+'.jpg'
    console.log('fileName', fileName)
    // var blobFile = new FileReader(blob, "img.jpg", { type: "image/jpeg" });
    // console.log('blobFile', blobFile)

    const imgOptions = {
      maxSizeMB: 0.75,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };

    // //   compressing file
    // const compressedFile = await imageCompression(blob, imgOptions);

    // console.log(
    //   "compressedFile instanceof Blob",
    //   compressedFile instanceof Blob
    // ); // true
    // console.log(
    //   `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
    // ); // smaller than maxSizeMB

    // compressedFile.name = 'hello.jpg'

    // console.log('compressedFile',compressedFile)

    // classList.add("mystyle")

   setPosterImg(canvasImg)
   console.log(document.getElementById("videoPlayer"))
   console.log(document.getElementById("videoPlayer").hasChildNodes())
  //  console.log(document.getElementsByClassName('react-player__preview').classList.add("img-fluid"))

  //  document.getElementsByClassName('react-player__preview').classList.add("img-fluid")

  }
  catch(err) {
    console.log('error', err)
  }


}

// console.log(document.getElementsByClassName('react-player__preview'))




  function handleDimension() {
    if (orientBtnText === "Landscape") {
      console.log("setting to portrait");
      setWidthAspect(3);
      setHeightAspect(4);
      setOrientBtnText("Portrait");
    } else {
      console.log("setting to landscape");
      setWidthAspect(4);
      setHeightAspect(3);
      setOrientBtnText("Landscape");
    }
  }

  async function handleNext() {
    await getCroppedFile();
    setPage((page) => page + 1);
  }

  function handleBack() {
    setPage((page) => page - 1);
  }

  //   const title = isProfile ? "Edit profile picture" : "Create post";
  const cancelLink = isProfile ? "/edit-profile" : "/guardian/" + user.id;
  const isDisabled = mediaSrc === null || page !== 1 ? true : false;
  const isAddPhotoDisabled = page !== 1 ? true : false;

  // console.log('mediaSrc', mediaSrc)
  console.log("mediaType", mediaType);

  return (
    <>
      {page === 1 ? (
        <NavTop
          title="Create Post"
          isDisabled={isDisabled}
          isLeftLink={true}
          leftText={"Cancel"}
          rightText={"Next"}
          leftLink={cancelLink}
          rightOnClick={handleNext}
        />
      ) : (
        <NavTop
          title="Create Post"
          isDisabled={false}
          isLeftLink={false}
          leftText={"Back"}
          rightText={"Post"}
          leftOnClick={handleBack}
          rightOnClick={uploadFileS3}
        />
      )}
      {/* <Container>
    
    </Container> */}
{/* 
      <PageLoader loading={loading} />
      {!loading && imgSrc === null && (
        <TextContainer text="Add a photo"/>
      )} */}
      {page === 2 ?
      <Container className="pt-5">
        <Row className="pt-5">
          <Col>
          <img src={croppedImg} width="150" height="160">
          </img>
          </Col>
        </Row>
      </Container>

        // <PostCaptionContainer
        //   img={croppedImg}
        //   width={widthAspect}
        //   height={heightAspect}
        //   imgSize={20}
        //   textareaPlaceholder={"Add your caption"}
        //   captionValue={captionBio}
        //   onChange={setCaptionBio}
        //   values={values}
        //   setValues={setValues}
        //   // taggedCompanions={taggedCompanions}
        //   // setTaggedCompanions={setTaggedCompanions}
        //   userCompanions={userCompanions}
        // />
      :
      !loading && mediaSrc === null? (
        <Container>
          <TextContainer text="Add a photo or video" />
          {/* <Row className="pt-3 pb-5">
            <Col className="pb-5">
          <video width="100%" height="100%" controls id="video"
            // crossOrigin="anonymous"
            // src="https://www.youtube.com/watch?v=i072e-AW7kg&ab_channel=GogginsClips"
            src='https://beaukohtao-content91826-kohenv.s3-us-west-2.amazonaws.com/public/IMG_0113.mp4#t=20'
            ></video>
            </Col>
             </Row>
            
            <Row className="pt-3 pb-5">
            <Col className="pb-5">
              <Button onClick={createVideoImg}>Create video image</Button>
            </Col>
          </Row> */}

        </Container>
      ) : mediaSrc !== null && mediaType === "video" ? (
        <Container className="pt-5">
          {/* <Row className="pt-5">
            <Col>
            <video width="100%" height="100%" controls id="video"
            // crossOrigin="anonymous"
            // src="https://www.youtube.com/watch?v=i072e-AW7kg&ab_channel=GogginsClips"
            src={mediaSrc}
            ></video>

            </Col>
          </Row> */}
          <Row className="py-5">
            <Col>
              <ReactPlayer
                url={[{src:mediaSrc, type:"video/mp4"}]}
                controls={true}
                width={"100%"}
                height={"20rem"}
                id="videoPlayer"
                light={posterImg===null ? false : posterImg}
              />
            </Col>
          </Row>
          <Row>
            <Col>
            <canvas id="canvas" className="d-none">

            </canvas>
            </Col>
          </Row>
          
          {/* <Row className="pt-3">
            <Col>
            <video width="100%" height="100%" controls id="video"
            // crossOrigin="anonymous"
            // src="https://www.youtube.com/watch?v=i072e-AW7kg&ab_channel=GogginsClips"
            src='https://beaukohtao-content91826-kohenv.s3-us-west-2.amazonaws.com/public/IMG_0113.mp4#t=20'
            ></video>
            </Col>
          </Row> */}
          <Row className="pt-3 pb-5">
            <Col className="pb-5">
              <Button onClick={getBlob}>Create video image</Button>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="crop-container">
          <Cropper
            image={mediaSrc}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={isProfile ? 1 : widthAspect / heightAspect}
            cropShape={isProfile ? "round" : "rect"}
            showGrid={true}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            restrictPosition={false}
          />
        </div>
      )
}

      <NavBottom
        isDisabled={isDisabled}
        isAddPhotoDisabled={isAddPhotoDisabled}
        addPhotoBtnText={mediaSrc === null ? "Add" : "Change"}
        orientBtn={orientBtnText}
        handleChange={handleChange}
        handleRotation={handleRotation}
        handleDimension={handleDimension}
      />
    </>
    // <AddEditImagesContainer isProfile={isProfile} isCompanion={isCompanion}/>
  );
}

export default CreatePost;
