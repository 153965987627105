import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import TopNavGeneric from "../../components/Navs/TopNavMain";
import PasswordRequirementsPopover from "../../components/OtherComponents/PopoverPasswordRequirements";
import ErrorMessageRow from "../../components/ContainersRows/RowErrorMessage";
import SetNewPasswordHandler from "../../handlers/AuthHandlers/SetNewPasswordHandler";
import InstructionsMessageRow from "../../components/ContainersRows/RowInstructionsMessage";
import SubmitButtonContainer from "../../components/ContainersRows/ContainerSubmitButton";

function SetNewPassword() {
  const [
    newPasswordObject,
    error,
    inputErrors,
    handleChange,
    handleSubmit
  ] = SetNewPasswordHandler();

  const row_mb = "mb-2";

  const isDisabled =
    !inputErrors.code.error &
    !inputErrors.email.error &
    !inputErrors.password.error &
    !inputErrors.confirmPassword.error
      ? false
      : true;

  return (
    <>
      <TopNavGeneric
        title="Set new password"
        addLeftIcon={true}
        leftIconPath="/forgot-password"
        leftIcon="MdArrowBack"
        disableRightIcon={true}
        rightIcon="MdArrowBack"
      />
      <Container className="my-4">
        <InstructionsMessageRow
          rowClass={row_mb}
          text="Enter the code from the email we just sent you to change your
              password"
        />
        <Row className={`${row_mb}`}>
          <Col>
            <FormControl
              className={inputErrors.code.valid}
              type="text"
              placeholder="Enter code"
              name="code"
              maxLength="10"
              value={newPasswordObject.code}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className={`${row_mb}`}>
          <Col>
            <FormControl
              className={inputErrors.email.valid}
              type="text"
              placeholder="Enter email"
              name="email"
              maxLength="50"
              value={newPasswordObject.email}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className={`${row_mb}`}>
          <Col>
            <FormControl
              className={inputErrors.password.valid}
              type="password"
              placeholder="New password"
              name="password"
              maxLength="100"
              value={newPasswordObject.password}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className={`${row_mb}`}>
          <Col>
            <FormControl
              className={inputErrors.confirmPassword.valid}
              type="password"
              placeholder="Confirm new password"
              name="confirmPassword"
              maxLength="100"
              value={newPasswordObject.confirmPassword}
              onChange={handleChange}
            />
            <PasswordRequirementsPopover />
          </Col>
        </Row>
        {error.isError && (
          <ErrorMessageRow rowClass={row_mb} message={error.message} />
        )}
      </Container>
      <SubmitButtonContainer
        text="Submit"
        rowClass={row_mb}
        handleSubmit={handleSubmit}
        disabled={isDisabled}
      />
    </>
  );
}

export default SetNewPassword;
