import React, { useState, useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavTop from "../components/Navs/TopNavUpload";
import ImageUploader from "react-images-upload";
import BottomNavPostUpload from "../components/Navs/BottomNavPostUpload";
import imageCompression from "browser-image-compression";
import { v4 as uuidv4 } from "uuid";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { useHistory, useParams } from "react-router-dom";
import { MdArrowBack, MdSettings, MdSearch } from "react-icons/md";
import { Context } from "../Context";
import { getUserCompanionInfo as GetUserCompanionInfo } from "../graphql/customQueries";
import Form from 'react-bootstrap/Form'
import config from "../aws-exports";
import {
    // updateUser as UpdateUser,
    // updateCompanion as UpdateCompanion,
    createPost as CreatePost,
    // createCompanion as CreateCompanion,
    // createGuardianshipLink as CreateGuardianshipLink,
    createTaggedLink as CreateTaggedLink,
  } from "../graphql/mutations";

function CreateNewPost() {
    const {
        aws_user_files_s3_bucket_region: region,
        aws_user_files_s3_bucket: bucket,
      } = config;
  const [pictures, setPictures] = useState([]);
  const [companions, setCompanions] = useState([]);
  const [optionsState, setOptionsState] = useState({id: "", value: ""})
  const history = useHistory();
  const { user } = useContext(Context);

  console.log("user", user);
  console.log("companions", companions);

  useEffect(() => {
    // async function getImageFromS3() {
    // try {
    //   const test = await Storage.get('d2f2e979-5545-4ef9-925c-176b82049aabyogi.jpeg',
    //    { level: 'public', identityId:'us-west-2:f96f457e-ef64-4ae0-9100-8238a69addb8' });

    //    console.log('test', test)

    // }catch (err) {
    //   console.log("error: ", err);
    // }

    async function getCompanionsDetails(id) {
      try {
        const userCompanionsResponse = await API.graphql(
          graphqlOperation(GetUserCompanionInfo, { id: id })
        );

        const companionsInfo =
          userCompanionsResponse.data.getUser.companions.items;

          console.log(companionsInfo)

          setCompanions(companionsInfo)

        const companionsObj = companionsInfo.map( (companion)  => {
        //   console.log('map', companion);
        //   const key = companion.profilePicIcon.key;
        //   const profPic = await Storage.get(key);
        //   console.log(key, profPic)
        //     console.log({ ...companion, profPic });
          return companion.companion;
        });

        // Promise.all(companionsObj).then((results) => {
        //   //   console.log("results", results);
          setCompanions(companionsObj);
        //   //   return results
        // });

        console.log("items", userCompanionsResponse.data.getUser.companions);
      } catch (err) {
        console.log("error: ", err);
      }
    }
    console.log("testing");
    //   getImageFromS3()
    getCompanionsDetails(user.id);
  }, []);

  const onDrop = (picture) => {
    console.log("picture", picture);
    setPictures(picture);
  };

  const goBack = () => {
    history.goBack();
  };

  const testS3upload = async () => {
    try {
      console.log("test");

      const imgOptions = {
        maxSizeMB: 0.75,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      };
      //   compressing file
      const fileName = pictures[0].name;
      const mimeType = pictures[0].type;
      const compressedFile = await imageCompression(pictures[0], imgOptions);

      console.log("compressedFile", compressedFile);

      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(`compressedFile size ${compressedFile.size} MB`); // smaller than maxSizeMB

      const imgKey = `${uuidv4()}${fileName}`;

      const uploadS3Response = await Storage.put(imgKey, compressedFile, {
        contentType: mimeType,
        // level: 'private'
      });

      console.log("uploadResponseIconImg", uploadS3Response);

      const imgForUpload = {
        bucket,
        key: imgKey,
        region,
      };

    //   ? setImgOrientation("portrait")
    //   : setImgOrientation("landscape");

      const inputData = {
        posterId: user.id,
        postImg: imgForUpload,
        caption: "",
        imgOrientation: "portait",
        postType: "public",
      };

      console.log("inputData", inputData);

      const createPostResponse = await API.graphql(
        graphqlOperation(CreatePost, { input: inputData })
      );

      console.log('createPostResponse', createPostResponse)

      if (optionsState.id != "") {
        const createPostId = createPostResponse.data.createPost.id;


        const createTaggedLinkResponse = await API.graphql(
          graphqlOperation(CreateTaggedLink, {
            input: {
              taggedLinkPostId: createPostId,
              taggedLinkCompanionId: optionsState.id,
            },
          })
        );
  
        console.log(createTaggedLinkResponse);
      }

      history.push("/guardian/" + user.id);
      

      
    } catch (err) {
      console.log("error: ", err);
    }
  };

  let companionsOptions = companions.map((companion) => {
    console.log("result", companion);
    return (
        <>
        <option key={companion.id} id={companion.id}>{companion.name}</option>
        </>
    )

  })

  function handleChange(event) {
      console.log('event.target', event.target.value)
      console.log('event.target.key', event.target.key)
      console.log('event.target.id', event.target[event.target.selectedIndex].id )
    const optionId = event.target[event.target.selectedIndex].id
    const optionValue = event.target.value
    const optionObj = {id: optionId, value: optionValue}

      setOptionsState(optionObj)
  }

  console.log('optionObj', optionsState)

  console.log("pictures.length", pictures.length);
  console.log("other picture", pictures);
  console.log("other picture", pictures[0]);

  return (
    <>
      <Navbar className="bg-white px-2 py-0 border-bottom" fixed="top">
        <Nav className="mr-auto">
          <Button onClick={goBack} variant="white" className="p-1">
            <MdArrowBack size="1.5em" />
          </Button>
        </Nav>
        <Navbar.Brand className="mx-auto">Create Post</Navbar.Brand>
        <Nav className="ml-auto">
          <Button variant="white" className="p-1" disabled={true}>
            <MdArrowBack size="1.5em" className="text-white" />
          </Button>
        </Nav>
      </Navbar>

      <Container className="pt-4">
        <Row>
          <ImageUploader
            //   buttonText={'hello'}
            withIcon={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".gif", ".png", ".gif", , ".jpeg"]}
            maxFileSize={5242880}
            withPreview={true}
            singleImage={true}
          />
        </Row>
        {/* <Row>
            <img src='https://beaukohtao-content91826-kohenv.s3-us-west-2.amazonaws.com/public/7b79dadf-ebd8-4378-85a0-289be80092acIMG_3901.JPG' />
        </Row> */}
      </Container>
      {pictures.length >0 &&
      <Container>
      <Form>
        <Form.Group>
          <Form.Label>Tag a companion</Form.Label>
          <Form.Control as="select" onChange={handleChange}>
            <option></option>
            {companionsOptions}
          </Form.Control>
        </Form.Group>
      </Form>
      </Container>
}
      <Navbar className="bg-white border-top" fixed="bottom">
        <Nav className="mx-auto">
          <Button
            onClick={testS3upload}
            disabled={pictures.length === 0 ? true : false}
          >
            Upload
          </Button>
        </Nav>
      </Navbar>
    </>
  );
}

export default CreateNewPost;
