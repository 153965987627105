import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

function PageLinkRow(props) {
  return (
    <>
      <Row className={props.rowClass}>
        <Col>
          <div className="d-flex justify-content-start">
            <Link to={props.linkTo} className="text-info">
              <p>{props.text}</p>
            </Link>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default PageLinkRow;
