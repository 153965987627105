import React from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaInfoCircle } from "react-icons/fa";

function PasswordRequirementsPopover() {
  const popoverContent = (
    <>
      <p className="text-muted m-0">Password requirements:</p>
      <ul>
        <li className="text-muted">at least than 6 characters</li>
      </ul>
    </>
  );

  const popoverContentTOUSELATER = (
    <>
      <p className="text-muted m-0">Password requirements:</p>
      <ul>
        <li className="text-muted">between 8 and 100 characters</li>
        <li className="text-muted">1 uppercase letter</li>
        <li className="text-muted">1 lowercase letter</li>
        <li className="text-muted">1 number</li>
        <li className="text-muted">1 special character: !@#$%^&*</li>
      </ul>
    </>
  );

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content style={{ fontSize: "0.8em" }}>
        {popoverContent}
      </Popover.Content>
    </Popover>
  );
  
  return (
    <>
      <div className="list-inline-item mr-0">
        <small className="form-text text-muted ">Password requirements</small>
      </div>
      <div className="list-inline-item">
        <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
          <div className="pb-2 pl-2 pr-2">
            <FaInfoCircle color="primary" />
          </div>
        </OverlayTrigger>
      </div>
    </>
  );
}

export default PasswordRequirementsPopover;
