import { useState } from "react";

function useValidation() {
  const [inputErrors, setInputErrors] = useState({
    username: {error: true, valid:""},
    email: {error: true, valid:""},
    password: {error: true, valid:""},
    oldPassword: {error: true, valid:""},
    confirmPassword: {error: true, valid:""},
    sameOldNewPassword: {error: true, valid:""},
    code: {error: true, valid:""}
  });

  function validLabel(bool) {
    return bool ? "is-invalid" : "is-valid"
  } 

  const minUsernameLength = 2;
  const minCodeLength = 3;
  const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  const passwordRegex = /[a-zA-Z0-9!@#$%^&*]{6,}/;
  // password regex to use later when password is made more stringent
  const passwordRegexTOUSELATER = /(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}/;

  function validation(name, value, obj) {
    // Validation for user Authentication (useranme, email, passwords, codes)
    if (name === "username") {
      if (value.length >= minUsernameLength) {
        setInputErrors({
          ...inputErrors,
          [name]: {error: false, valid: validLabel(false)}
        });
      } else {
        setInputErrors({
          ...inputErrors,
          [name]: {error: true, valid: validLabel(true)}
        });
      }
    } 
    
    if (name === "password") {
      if (passwordRegex.test(value)) {
        setInputErrors({
          ...inputErrors,
          [name]: {error: false, valid: validLabel(false)},
          confirmPassword: {error: !(value === obj.confirmPassword), valid: validLabel(!(value === obj.confirmPassword))},
          sameOldNewPassword: {error: value === obj.oldPassword, valid: validLabel(value === obj.oldPassword)}
        });
      } else {
        setInputErrors({
          ...inputErrors,
          [name]: {error: true, valid: validLabel(true)},
          confirmPassword: {error: !(value === obj.confirmPassword), valid: validLabel(!(value === obj.confirmPassword))},
          sameOldNewPassword: {error: value === obj.oldPassword, valid: validLabel(value === obj.oldPassword)}
        });
      }
    } 
    
    if (name === "oldPassword") {
      if (passwordRegex.test(value)) {
        setInputErrors({
          ...inputErrors,
          [name]: {error: false, valid: validLabel(false)},
          sameOldNewPassword: {error: value === obj.password, valid: validLabel(value === obj.password)}
        });
      } else {
        setInputErrors({
          ...inputErrors,
          [name]: {error: true, valid: validLabel(true)},
          sameOldNewPassword: {error: value === obj.password, valid: validLabel(value === obj.password)}
        });
      }
    } 
    
    if (name === "email") {
      if (emailRegex.test(value)) {
        setInputErrors({
          ...inputErrors,
          [name]: {error: false, valid: validLabel(false)}
        });
      } else {
        setInputErrors({
          ...inputErrors,
          [name]: {error: true, valid: validLabel(true)}
        });
      }
    } 
    
    if (name === "confirmPassword") {
      if (value === obj.password) {
        setInputErrors({
          ...inputErrors,
          [name]: {error: false, valid: validLabel(false)}
        });
      } else {
        setInputErrors({
          ...inputErrors,
          [name]: {error: true, valid: validLabel(true)}
        });
      }
    } 
    
    if (name === "code") {
      if (value.length >= minCodeLength) {
        setInputErrors({
          ...inputErrors,
          [name]: {error: false, valid: validLabel(false)}
        });
      } else {
        setInputErrors({
          ...inputErrors,
          [name]: {error: true, valid: validLabel(true)}
        });
      }
    }
  }

  return [inputErrors, validation];
}

export default useValidation;
