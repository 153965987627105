import React, { useContext, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-bootstrap/Media";
import { Link, useParams } from "react-router-dom";
import TopNavGeneric from "../components/Navs/TopNavMain";
import BottomNavGeneric from "../components/Navs/BottomNavMain";
import { Context } from "../Context";
import Button from "react-bootstrap/Button";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { getCompanion as GetCompanion } from "../graphql/queries";
import { GiBerriesBowl } from "react-icons/gi";
import { FaClipboardList, FaNotesMedical, FaHome } from "react-icons/fa";
import {
  createRequest as CreateRequest,
  deleteRequest as DeleteRequest,
  createGuardianshipLink as CreateGuardianshipLink,
  deleteGuardianshipLink as DeleteGuardianshipLink,
} from "../graphql/mutations";
import { getTaggedCompanionPosts as GetTaggedCompanionPosts } from "../graphql/customQueries";
import PageLoader from "../components/OtherComponents/LoaderPage";
import { HiShieldCheck } from "react-icons/hi";
import { GoAlert } from "react-icons/go";

function CompanionProfile() {
  const { user } = useContext(Context);
  const [profilePic, setProfilePic] = useState("");
  const [companion, setCompanion] = useState("");
  const [loading, setLoading] = useState(true);
  let { companionId } = useParams();
  const [posts, setPosts] = useState([]);
  const [isGuardian, setIsGuardian ]= useState(false)

  useEffect(() => {
    setLoading(true);
    async function getCompanionDetails(id) {
      try {
        const {
          data: { getCompanion },
        } = await API.graphql(graphqlOperation(GetCompanion, { id: id }));
        console.log(getCompanion);
        setCompanion(getCompanion);

        const guardiansInfo = getCompanion.guardians.items

        const guardianList = guardiansInfo.filter(guardian => {
          if (guardian.guardianshipLinkGuardianId === user.id) {
            return true
          }
          else {
            return false
          }
        }).map(guardian => {
          return guardian.guardianshipLinkGuardianId
        })

        console.log('guardianList',guardianList, guardianList.includes(user.id))
        setIsGuardian(guardianList.includes(user.id))

        const profilePicData = await Storage.get(
          getCompanion.profilePicIcon.key
        );
        setProfilePic(profilePicData);

        const getTaggedCompanionPostsResponse = await API.graphql(
          graphqlOperation(GetTaggedCompanionPosts, { id: id })
        );
        console.log(
          "GetTaggedCompanionPostsResponse",
          getTaggedCompanionPostsResponse
        );

        const postInfo =
          getTaggedCompanionPostsResponse.data.getCompanion.postsTagged.items;

        console.log("postInfo", postInfo);

        let postPic = "";
        if (postInfo.length > 0) {
          const postObj = postInfo.map(async (post) => {
            const key = post.post.postImg.key;
            postPic = await Storage.get(key);
            return { ...post.post, postPic };
          });

          Promise.all(postObj).then((results) => {
            setPosts(results);
          });
        }
      } catch (err) {
        console.log("error: ", err);
      }
    }

    getCompanionDetails(companionId);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const width = "100%";

  let posts0Component = posts
    .filter((post, index) => {
      return index % 3 === 0;
    })
    .map((post) => {
      return (
        <div key={post.id} className="pb-1 px-1">
          <img width={width} className="" src={post.postPic} />
        </div>
      );
    });

  let posts1Component = posts
    .filter((post, index) => {
      return (index - 1) % 3 === 0;
    })
    .map((post) => {
      return (
        <div key={post.id} className="pb-1">
          <img width={width} className="" src={post.postPic} />
        </div>
      );
    });

  let posts2Component = posts
    .filter((post, index) => {
      return (index + 1) % 3 === 0;
    })
    .map((post) => {
      return (
        <div key={post.id} className="pb-1 px-1">
          <img width={width} className="" src={post.postPic} />
        </div>
      );
    });

  const constainerShow = loading ? "mt-5 mb-5 pb-5 d-none" : "mt-5 mb-5 pb-5";

  return (
    <>
      <TopNavGeneric
        title={companion.name}
        addRightIcon={isGuardian}
        rightIconPath={"/settings/companion/" + companion.id}
        rightIcon="MdSettings"
        addLeftIcon={true}
        disableLeftIcon={isGuardian}
        leftIcon="MdArrowBack"
        leftIconPath={"#"}
      />
      <PageLoader loading={loading} />
      <Container className={constainerShow}>
        <Media>
          <img
            width={"70rem"}
            height={"70rem"}
            className="mr-2 rounded-circle"
            src={profilePic}
          />
          <Media.Body className="align-self-center">
            <Container className="p-0 ">
              <Row className="ml-auto mr-auto d-inline">
                <Container className="p-0 text-center d-inline">
                  <Button variant="white" className="p-0">
                    <Container>
                      <Row className="">
                        <Col className="p-0 text-center">
                          <p
                            className="m-0 font-weight-bold"
                            style={{ fontSize: "0.8rem" }}
                          >
                            {companion.guardians === undefined
                              ? 0
                              : companion.guardians.items.length}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-0 text-center">
                          <p className="m-0" style={{ fontSize: "0.8rem" }}>
                            {companion.guardians === undefined
                              ? "Guardian"
                              : companion.guardians.items.length === 1
                              ? "Guardian"
                              : "Guardians"}
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </Button>
                  <Button
                    variant="info"
                    className="ml-3 py-0 px-3 d-inline"
                    disabled={true}
                  >
                    <small>Message</small>
                  </Button>
                </Container>
              </Row>
            </Container>
          </Media.Body>
        </Media>
        <Container className="pt-3">
          <Row className="border-top border-bottom">
            <Col className="d-inline px-0 d-flex justify-content-center">
              <div className="d-inline">
                {companion.hasFood ? (
                  <HiShieldCheck size="1em" className="text-success d-inline" />
                ) : (
                  <GoAlert size="1em" className="text-danger d-inline" />
                )}
                <small className="pl-1 d-inline">Food</small>
              </div>
              <div className="pl-3 d-inline">
                {companion.hasMedicalCare ? (
                  <HiShieldCheck size="1em" className="text-success d-inline" />
                ) : (
                  <GoAlert size="1em" className="text-danger d-inline" />
                )}
                <small className="pl-1 d-inline">Medical care</small>
              </div>
              <div className="pl-3 d-inline">
                {companion.hasShelter ? (
                  <HiShieldCheck size="1em" className="text-success d-inline" />
                ) : (
                  <GoAlert size="1em" className="text-danger d-inline" />
                )}
                <small className="pl-1 d-inline">Shelter</small>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="py-3">
            <small style={{ whiteSpace: "pre-wrap" }}>{companion.bio}</small>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col className="p-0">{posts0Component}</Col>
            <Col className="p-0">{posts1Component}</Col>
            <Col className="p-0">{posts2Component}</Col>
          </Row>
        </Container>
      </Container>
      <BottomNavGeneric companionIconFilled={true} />
    </>
  );
}

export default CompanionProfile;
