import React, { useContext, useState, useEffect, useCallback } from "react";
import { uuid } from 'uuidv4';
import { Context } from "../../Context";
import { Storage, API, graphqlOperation } from "aws-amplify";
import {
  updateUser as UpdateUser,
  updateCompanion as UpdateCompanion,
  createPost as CreatePost,
  createCompanion as CreateCompanion,
  createGuardianshipLink as CreateGuardianshipLink,
  createTaggedLink as CreateTaggedLink,
} from "../../graphql/mutations";
import {
  getUser as GetUser,
  getCompanion as GetCompanion,
} from "../../graphql/queries";
import config from "../../aws-exports";
import imageCompression from "browser-image-compression";
import { useHistory } from "react-router-dom";

function CropImageHandler(isProfile, isCompanion, userType, userId, values) {
  const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket,
  } = config;
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const { user, setUser } = useContext(Context);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImg, setCroppedImg] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [captionBio, setCaptionBio] = useState("");
  const [companionName, setCompanionName] = useState("");
  const [isYourCompanion, setIsYourCompanion] = useState(false);
  const [hasFood, setHasFood] = useState(false);
  const [hasShelter, setHasShelter] = useState(false);
  const [hasMedicalCare, setHasMedicalCare] = useState(false);
  const [imgOrientation, setImgOrientation] = useState("");

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [mediaType, setMediaType] = useState('image')
  const [mediaSrc, updateMediaSrc] = useState(null);
  const [file, updateFile] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  

  useEffect(() => {
    //   need to add local variable mounted to avoid memory leak
    // https://www.rockyourcode.com/avoid-memory-leak-with-react-setstate-on-an-unmounted-component/
    let mounted = true;
    setLoading(true);
    // Maybe fetch image in Context...so image already there..

    async function getProfileDetails(id) {
      try {
        let profilePicData = null;

        if (isCompanion) {
          // console.log('hello')
          // console.log('id', id)
          const {
            data: { getCompanion },
          } = await API.graphql(graphqlOperation(GetCompanion, { id: id }));
          // console.log('getCompanion',getCompanion)
          profilePicData = await Storage.get(getCompanion.profilePic.key);
        } else {
          const {
            data: { getUser },
          } = await API.graphql(graphqlOperation(GetUser, { id: id }));
          profilePicData = await Storage.get(getUser.profilePic.key);
        }

        if (mounted) {
          updateMediaSrc(profilePicData);
        }
        // creating a new file from the URL so can edit it
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", profilePicData);
        xhr.responseType = "blob";
        xhr.onload = function () {
          blob = xhr.response;
          const newFile = new File([blob], `ProfilePic.JPG`);

          if (mounted) {
            updateFile(newFile);
          }
        };
        xhr.send();
      } catch (err) {
        console.log("error: ", err);
        setLoading(false);
      }
    }

    isProfile && getProfileDetails(userId);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
      mounted = false;
    };
  }, []);

  // function handles image / video upload
  function handleChange(event) {
    const {
      target: { files },
    } = event;

    if (files.length > 0) {

      console.log('files', files)
      console.log('fileType', files[0].type.split("/")[0])

      const mediaType = files[0].type.split("/")[0]
      setMediaType(mediaType)

          // accounts for change event when no image is selected
      const [media] = files || [];
      updateFile(media);

      const currentFile = files[0];
      const myFileItemReader = new FileReader();

      myFileItemReader.addEventListener(
        "load",
        () => {
          const myResult = myFileItemReader.result;
          updateMediaSrc(myResult);
        },
        false
      );

      myFileItemReader.readAsDataURL(currentFile);

      console.log("file", media);
    }

  }

  function handleRotation() {
    if (rotation === 0) {
      setRotation(270);
    } else {
      setRotation(rotation - 90);
    }
  }

  //   Below code is predominately takens from: https://codesandbox.io/s/q8q1mnr01w?file=/src/cropImage.js
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  /**
   * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
   * @param {File} image - Image File url
   * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
   * @param {number} rotation - optional rotation parameter
   */


  async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    // console.log('imageSrc', imageSrc)
    const image = await createImage(imageSrc);
    // console.log('image', image)
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // set imgOrientaiton based on canvas dimensions
    canvas.height > canvas.width
      ? setImgOrientation("portrait")
      : setImgOrientation("landscape");

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  }

  //   function to take cropped image and return a cropped file, which we can upload
  const getCroppedFile = async () => {
    try {
      const croppedImage = await getCroppedImg(
        mediaSrc,
        croppedAreaPixels,
        rotation
      );
      console.log("croppedImage", croppedImage);

      let blob = await fetch(croppedImage).then((r) => r.blob());
      var blobFile = new FileReader([blob], file.name, { type: "image/jpeg" });
      
      // COMPRESSING AND UPLOADING IMAGES TO S3
      // NORMAL
      const imgOptions = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      };
      //   compressing file
      const compressedFile = await imageCompression(blob, imgOptions);

      const compressedFileURL = URL.createObjectURL(compressedFile)
      setCroppedImg(compressedFileURL);

      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size} MB`
      ); // smaller than maxSizeMB

      return blobFile;
    } catch (e) {
      console.error(e);
    }
  };

  // function to upload files
  const uploadFileS3 = async () => {
    try {
      console.log("awaiting get cropped file");
      const croppedFile = await getCroppedFile();
      const { name: fileName, type: mimeType } = croppedFile;

      console.log("mimetype", mimeType);

      // COMPRESSING AND UPLOADING IMAGES TO S3
      // NORMAL
      const imgOptions = {
        maxSizeMB: 0.75,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      };
      //   compressing file
      const compressedFile = await imageCompression(croppedFile, imgOptions);

      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      // setting key for image upload
      const imgKey = `${uuid()}${user.id}${fileName}`;

      //   uploading file to S3
      const uploadResponseImg = await Storage.put(imgKey, compressedFile, {
        contentType: mimeType,
        // level: 'private'
      });

      console.log("uploadResponseImg", uploadResponseImg);

      //    image properties for User schema
      const imgForUpload = {
        bucket,
        key: imgKey,
        region,
      };

      if (!isProfile && !isCompanion) {
        // POST
        const inputData = {
          posterId: user.id,
          postImg: imgForUpload,
          caption: captionBio,
          imgOrientation: imgOrientation,
          postType: "public",
        };

        console.log("inputData", inputData);

        const createPostResponse = await API.graphql(
          graphqlOperation(CreatePost, { input: inputData })
        );

        const createPostId = createPostResponse.data.createPost.id;
        console.log(createPostResponse.data);

        // ONBOARDING - post added
        if (!user.postAdded) {
          await API.graphql(
            graphqlOperation(UpdateUser, {
              input: {
                id: userId,
                postAdded: true,
              },
            })
          );
        }

        // Creating tagged companionship link
        if (values.length > 0) {
          var i = 0;

          while (values[i]) {
            const companionId = values[i].id;

            const createTaggedLinkResponse = await API.graphql(
              graphqlOperation(CreateTaggedLink, {
                input: {
                  taggedLinkPostId: createPostId,
                  taggedLinkCompanionId: companionId,
                },
              })
            );

            console.log(createTaggedLinkResponse);
            i++;
          }
        }

        history.push("/guardian/" + user.id);
      } else {
        //   Compress Icon files for profile pictures

        // ICON
        const imgIconOptions = {
          maxSizeMB: 0.05,
          maxWidthOrHeight: 200,
          useWebWorker: true,
        };

        //   compressing file to icon size
        const compressedIconFile = await imageCompression(
          croppedFile,
          imgIconOptions
        );

        const imgIconKey = `${uuid()}${user.id}Icon${fileName}`;

        const uploadResponseIconImg = await Storage.put(
          imgIconKey,
          compressedIconFile,
          {
            contentType: mimeType,
            // level: 'private'
          }
        );

        console.log("uploadResponseIconImg", uploadResponseIconImg);

        // Getting link so do not need to call S3 with key
        // const profilePicLink = await Storage.get(imgIconKey);

        const imgIconForUpload = {
          bucket,
          key: imgIconKey,
          region,
        };

        // ADD COMPANION
        if (isCompanion && !isProfile) {
          const inputData = {
            // id: user.id,
            name: companionName,
            profilePic: imgForUpload,
            profilePicIcon: imgIconForUpload,
            // profilePicLink: profilePicLink,
            bio: captionBio,
            hasShelter: hasShelter,
            hasMedicalCare: hasMedicalCare,
            hasFood: hasFood,
          };

          const createCompanionResponse = await API.graphql(
            graphqlOperation(CreateCompanion, { input: inputData })
          );

          console.log(createCompanionResponse);

          // ONBOARDING - companion added
          if (!user.companionAdded) {
            await API.graphql(
              graphqlOperation(UpdateUser, {
                input: {
                  id: user.id,
                  companionAdded: true,
                },
              })
            );
          }

          const companionId = createCompanionResponse.data.createCompanion.id;

          // Creating guardianship link if this companion is yours
          if (isYourCompanion) {
            await API.graphql(
              graphqlOperation(CreateGuardianshipLink, {
                input: {
                  guardianshipLinkGuardianId: user.id,
                  guardianshipLinkCompanionId: companionId,
                },
              })
            );
          }

          history.push("/companions/" + user.id);
        } else {
          // PROFILES
          const inputData = {
            id: userId,
            profilePic: imgForUpload,
            profilePicIcon: imgIconForUpload,
            // profilePicLink: profilePicLink,
          };

          let updateResponse = null;
          // COMPANION PROFILE
          if (isCompanion) {
            console.log("updating companion");
            updateResponse = await API.graphql(
              graphqlOperation(UpdateCompanion, { input: inputData })
            );
          } else {
            // GUARDIAN PROFILE
            console.log("updating user");
            updateResponse = await API.graphql(
              graphqlOperation(UpdateUser, { input: inputData })
            );
            // ONBOARDING - add profile
            if (!user.profilePicAdded) {
              await API.graphql(
                graphqlOperation(UpdateUser, {
                  input: {
                    id: userId,
                    profilePicAdded: true,
                  },
                })
              );
            }
          }

          console.log(updateResponse);
          console.log("successfully uploaded image!");

          history.push(`/edit-profile/${userType}/${userId}`);
        }
      }
    } catch (err) {
      console.log("error: ", err);
    }
  };

  return [
    handleChange,
    handleRotation,
    crop,
    setCrop,
    loading,
    zoom,
    file,
    setZoom,
    uploadFileS3,
    onCropComplete,
    mediaSrc,
    mediaType,
    rotation,
    croppedImg,
    getCroppedFile,
    captionBio,
    setCaptionBio,
    companionName,
    setCompanionName,
    hasShelter,
    setHasShelter,
    hasMedicalCare,
    setHasMedicalCare,
    hasFood,
    setHasFood,
    isYourCompanion,
    setIsYourCompanion
  ];
}

export default CropImageHandler;
