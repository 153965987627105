import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import userAuthValidation from "../../hooks/userAuthValidation";
import { useHistory, useLocation } from "react-router-dom";

function ConfirmSignupHandler() {
  const history = useHistory();
  const [newUser, setNewUser] = useState({
    username: "",
    code: "",
    password: ""
  });
  const [error, setError] = useState({
    isError: false,
    message: ""
  });

  const [confirmationMessage, setConfirmationMessage] = useState("");

  const location = useLocation();
  useEffect(() => {
    if (typeof location.state !== "undefined") {
      setConfirmationMessage(location.state.confirmationMessage);
    }
  }, [location]);

  const [inputErrors, validation] = userAuthValidation();

  function handleChange(event) {
    const { name, value } = event.target;
    setNewUser({
      ...newUser,
      [name]: value
    });
    validation(name, value, newUser);
  }

  async function handleSubmit(event) {
    const { username, code } = newUser;

    try {
      await Auth.confirmSignUp(username, code, {
        forceAliasCreation: true
      });

      history.push({
        pathname: "/auth-confirmation",
        state: {
          confirmationMessage: "Verified! You can now log in.",
          buttonText: "Log in",
          path: "/login"
        }
      });
    } catch (error) {
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setError({
        isError: true,
        message: err.message
      });
    }
  }

  return [
    newUser,
    error,
    inputErrors,
    confirmationMessage,
    handleChange,
    handleSubmit
  ];
}

export default ConfirmSignupHandler;
