import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function SubmitButtonContainer(props) {
  return (
    <Container>
      <Row className={props.rowClass}>
        <Col className="text-center">
          <Button className="px-5" onClick={props.handleSubmit} disabled={props.disabled}>
            {props.text}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default SubmitButtonContainer