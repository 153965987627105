import React from "react";
import Navbar from "react-bootstrap/Navbar";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { IoMdSend } from "react-icons/io";
import SendMessageHandler from "../../handlers/ConversationHandlers/SendMessageHandler";

function SendMessageNav(props) {
  const [messageObject, handleChange, handleMessage] = SendMessageHandler(
    props.chatId
  );

  return (
    <>
      <Navbar className="bg-white border-top" fixed="bottom">
        <FormControl
          className="mr-2"
          type="text"
          placeholder="Message"
          name="message"
          value={messageObject.message}
          onChange={handleChange}
        />
        <Button variant="outline-success" onClick={handleMessage}>
          <IoMdSend />
        </Button>
      </Navbar>
    </>
  );
}

export default SendMessageNav;
