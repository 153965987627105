import React, { useEffect, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Context } from "./Context";

import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import SetNewPassword from "./pages/Auth/SetNewPassword";
import AuthConfirmation from "./pages/Auth/AuthConfirmation";
import ChangePassword from "./pages/Auth/ChangePassword";
import ConfirmSignup from "./pages/Auth/ConfirmSignup";
import ResendCode from "./pages/Auth/ResendCode";

import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
import Settings from "./pages/Settings";
import LocalResources from "./pages/LocalResources";
import Notifications from "./pages/Notifications";
import Emergency from "./pages/Emergency";
import About from "./pages/About";

import GuardianProfile from "./pages/GuardianProfile";
import CompanionProfile from "./pages/CompanionProfile";
import EditProfilePicture from "./pages/EditProfilePicture";
import EditProfile from "./pages/EditProfile";
import CreatePost from "./pages/CreatePost";
import CreateNewPost from "./pages/CreateNewPost";
import AddCompanion from "./pages/AddCompanion";
import Companions from "./pages/Companions";
import Search from "./pages/Search";
import AddGuardian from "./pages/AddGuardian";

import Conversations from "./pages/Message/Conversations";
import StartConversation from "./pages/Message/StartConversation";
import Chat from "./pages/Message/Chat";
import Posts from "./pages/Posts";

function App() {
  const { user, userCompanions, fetchSession, isAuthenticating, isAuthenticated } = useContext(
    Context
  );

  useEffect(() => {
    fetchSession();
  }, []);

  const AuthExploreRoute = ({ component, ...options }) => {
    console.log('options', options.computedMatch.params.guardianId)
    return isAuthenticated ? (
      <Route {...options} component={component} />
    ) : (
      <Redirect from={options.path} to="/" />
    );
  };

  const AuthRoute = ({ component, ...options }) => {
    return isAuthenticated  && user.id!== 'guest_user' ? (
      <Route {...options} component={component} />
    ) : (
      <Redirect from={options.path} to="/posts" />
    );
    // return <Redirect from="/" to="/"/>
  };

  //   const NoAuthRoute = ({ component, ...options }) => {
  // 	return !isAuthenticated ? <Route {...options} component={component} /> : <Redirect from={options.path} to="/welcome"/>
  // 	// return <Redirect from="/" to="/"/>
  //   };

  const NoAuthRoute = ({ component, ...options }) => {
    const finalComponent = !isAuthenticated ? component : Search;

    return <Route {...options} component={finalComponent} />;
  };


  const UserRoute = ({ component, ...options }) => {
    const id = options.computedMatch.params.id
    const userType = options.computedMatch.params.userType

    console.log('userType', userType)
    console.log('id', id)
    console.log('userCompanions', userCompanions)

    // check to see if user has right permissions to access certain pages
    if (userType === 'guardian') {
    // guardian page
      return isAuthenticated && user.id===id ? (
        <Route {...options} component={component} />
      ) : (
        <Redirect from={options.path} to="/" />
      );

    } else {
    // companion page
      return isAuthenticated && userCompanions.includes(id) ? (
        <Route {...options} component={component} />
      ) : (
        <Redirect from={options.path} to="/posts" />
      );

    }
  };

  const CompanionRoute = ({ component, ...options }) => {
    const companionId = options.computedMatch.params.companionId
    
    // check to see if user has right permissions to access certain pages
      return isAuthenticated && userCompanions.includes(companionId) ? (
        <Route {...options} component={component} />
      ) : (
        <Redirect from={options.path} to="/posts" />
      );
  };

  // Companion Route to add

  // Conversation Route to Add

  // Explore Route to include / customize

  return (
    <div className="">
      {!isAuthenticating && (
        <Switch>
          <NoAuthRoute exact path="/" component={Home} />
          <NoAuthRoute path="/login" component={Login} />
          <NoAuthRoute path="/signup" component={Signup} />
          <NoAuthRoute path="/confirm-signup" component={ConfirmSignup} />
          <NoAuthRoute path="/forgot-password" component={ForgotPassword} />
          <NoAuthRoute path="/resend-code" component={ResendCode} />
          <NoAuthRoute path="/set-new-password" component={SetNewPassword} />
          <NoAuthRoute path="/about" component={About} />
          <NoAuthRoute path="/emergency" component={Emergency} />

          {/* Explore Auth */}
          <AuthExploreRoute path="/guardian/:guardianId" component={GuardianProfile} />
          <AuthExploreRoute path="/companion/:companionId" component={CompanionProfile} />
          <AuthExploreRoute path="/companions/:guardianId" component={Companions} />
          <AuthExploreRoute path="/search" component={Search} />
          <AuthExploreRoute path="/posts" component={Posts} />
          <AuthExploreRoute path="/local-resources" component={LocalResources} />

          {/* Generic auth */}
          <AuthRoute path="/notifications" component={Notifications} />
          <AuthRoute exact path="/conversations" component={Conversations} />
          <AuthRoute path="/start-conversation" component={StartConversation} />
          <AuthRoute path="/change-password" component={ChangePassword} />
          <AuthRoute path="/create-post" component={CreatePost} />
          <AuthRoute path="/create-new-post" component={CreateNewPost} />
          <AuthRoute path="/add-companion" component={AddCompanion} />

        {/* User specific auth needed */}
          <UserRoute path="/settings/:userType/:id" component={Settings} />
          <UserRoute path="/edit-profile/:userType/:id" component={EditProfile} />
          <UserRoute path="/edit-profile-picture/:userType/:id" component={EditProfilePicture} />
          <CompanionRoute path="/add-guardian/:companionId" component={AddGuardian} />
          {/* Need to add conversation auth when ready */}
          <AuthRoute path="/conversations/:chatId" component={Chat} />          

          <Route path="/auth-confirmation" component={AuthConfirmation} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      )}
    </div>
  );
}

export default App;
