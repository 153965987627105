import React from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoIosArrowForward } from "react-icons/io";

function LinkComponent(props) {
  return (
    <Link to={{ pathname: props.path, name: props.name }}>
      <Row className="border d-flex align-items-center mx-1 py-2">
        <Col className="col-10">
          <p className="text-dark m-0">{props.name}</p>
        </Col>
        <Col className="col-2">
          <IoIosArrowForward size="1.5em" className="text-info" />
        </Col>
      </Row>
    </Link>
  );
}

export default LinkComponent;
