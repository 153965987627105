/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      user_id
      username
      preferred_username
      email
      conversations {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      postAdded
      posts {
        items {
          id
          posterId
          caption
          imgOrientation
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      profilePicLink
      awsIdentityId
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicAdded
      bio
      bioAdded
      followers {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationsCreated {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      requestsCreated {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      companions {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      companionAdded
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      user_id
      username
      preferred_username
      email
      conversations {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      postAdded
      posts {
        items {
          id
          posterId
          caption
          imgOrientation
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      profilePicLink
      awsIdentityId
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicAdded
      bio
      bioAdded
      followers {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationsCreated {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      requestsCreated {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      companions {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      companionAdded
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      user_id
      username
      preferred_username
      email
      conversations {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      postAdded
      posts {
        items {
          id
          posterId
          caption
          imgOrientation
          postType
          createdAt
          updatedAt
        }
        nextToken
      }
      profilePicLink
      awsIdentityId
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicAdded
      bio
      bioAdded
      followers {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      following {
        items {
          id
          followLinkFollowingId
          followLinkFollowerId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notificationsCreated {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      requestsCreated {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      companions {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      companionAdded
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      userToNotify {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      userToNotifyId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      userToNotify {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      userToNotifyId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      userToNotify {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      userToNotifyId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRequest = /* GraphQL */ `
  subscription OnCreateRequest {
    onCreateRequest {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      requestedUser {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      requestedUserId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      accepted
      current
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRequest = /* GraphQL */ `
  subscription OnUpdateRequest {
    onUpdateRequest {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      requestedUser {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      requestedUserId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      accepted
      current
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRequest = /* GraphQL */ `
  subscription OnDeleteRequest {
    onDeleteRequest {
      id
      type
      createdBy {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      createdById
      requestedUser {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      requestedUserId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      companionId
      accepted
      current
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation {
    onCreateConversation {
      id
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      associated {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation {
    onUpdateConversation {
      id
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      associated {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation {
    onDeleteConversation {
      id
      messages {
        items {
          id
          authorId
          content
          messageConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      associated {
        items {
          id
          convoLinkUserId
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      author {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      authorId
      content
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      author {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      authorId
      content
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      author {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      authorId
      content
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConvoLink = /* GraphQL */ `
  subscription OnCreateConvoLink {
    onCreateConvoLink {
      id
      user {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      convoLinkUserId
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConvoLink = /* GraphQL */ `
  subscription OnUpdateConvoLink {
    onUpdateConvoLink {
      id
      user {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      convoLinkUserId
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConvoLink = /* GraphQL */ `
  subscription OnDeleteConvoLink {
    onDeleteConvoLink {
      id
      user {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      convoLinkUserId
      conversation {
        id
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      poster {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      companionsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      posterId
      postImg {
        bucket
        region
        key
      }
      caption
      imgOrientation
      postType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      poster {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      companionsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      posterId
      postImg {
        bucket
        region
        key
      }
      caption
      imgOrientation
      postType
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      poster {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      companionsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      posterId
      postImg {
        bucket
        region
        key
      }
      caption
      imgOrientation
      postType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompanion = /* GraphQL */ `
  subscription OnCreateCompanion {
    onCreateCompanion {
      id
      name
      bio
      hasShelter
      hasFood
      hasMedicalCare
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicLink
      guardians {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      postsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompanion = /* GraphQL */ `
  subscription OnUpdateCompanion {
    onUpdateCompanion {
      id
      name
      bio
      hasShelter
      hasFood
      hasMedicalCare
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicLink
      guardians {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      postsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompanion = /* GraphQL */ `
  subscription OnDeleteCompanion {
    onDeleteCompanion {
      id
      name
      bio
      hasShelter
      hasFood
      hasMedicalCare
      profilePic {
        bucket
        region
        key
      }
      profilePicIcon {
        bucket
        region
        key
      }
      profilePicLink
      guardians {
        items {
          id
          guardianshipLinkGuardianId
          guardianshipLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      postsTagged {
        items {
          id
          taggedLinkPostId
          taggedLinkCompanionId
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          createdById
          userToNotifyId
          companionId
          createdAt
          updatedAt
        }
        nextToken
      }
      requests {
        items {
          id
          type
          createdById
          requestedUserId
          companionId
          accepted
          current
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGuardianshipLink = /* GraphQL */ `
  subscription OnCreateGuardianshipLink {
    onCreateGuardianshipLink {
      id
      guardian {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      guardianshipLinkGuardianId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      guardianshipLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGuardianshipLink = /* GraphQL */ `
  subscription OnUpdateGuardianshipLink {
    onUpdateGuardianshipLink {
      id
      guardian {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      guardianshipLinkGuardianId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      guardianshipLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGuardianshipLink = /* GraphQL */ `
  subscription OnDeleteGuardianshipLink {
    onDeleteGuardianshipLink {
      id
      guardian {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      guardianshipLinkGuardianId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      guardianshipLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTaggedLink = /* GraphQL */ `
  subscription OnCreateTaggedLink {
    onCreateTaggedLink {
      id
      post {
        id
        poster {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        companionsTagged {
          nextToken
        }
        posterId
        postImg {
          bucket
          region
          key
        }
        caption
        imgOrientation
        postType
        createdAt
        updatedAt
      }
      taggedLinkPostId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      taggedLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTaggedLink = /* GraphQL */ `
  subscription OnUpdateTaggedLink {
    onUpdateTaggedLink {
      id
      post {
        id
        poster {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        companionsTagged {
          nextToken
        }
        posterId
        postImg {
          bucket
          region
          key
        }
        caption
        imgOrientation
        postType
        createdAt
        updatedAt
      }
      taggedLinkPostId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      taggedLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTaggedLink = /* GraphQL */ `
  subscription OnDeleteTaggedLink {
    onDeleteTaggedLink {
      id
      post {
        id
        poster {
          id
          user_id
          username
          preferred_username
          email
          postAdded
          profilePicLink
          awsIdentityId
          profilePicAdded
          bio
          bioAdded
          companionAdded
          createdAt
          updatedAt
        }
        companionsTagged {
          nextToken
        }
        posterId
        postImg {
          bucket
          region
          key
        }
        caption
        imgOrientation
        postType
        createdAt
        updatedAt
      }
      taggedLinkPostId
      companion {
        id
        name
        bio
        hasShelter
        hasFood
        hasMedicalCare
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicLink
        guardians {
          nextToken
        }
        postsTagged {
          nextToken
        }
        notifications {
          nextToken
        }
        requests {
          nextToken
        }
        createdAt
        updatedAt
      }
      taggedLinkCompanionId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFollowLink = /* GraphQL */ `
  subscription OnCreateFollowLink {
    onCreateFollowLink {
      id
      following {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowingId
      follower {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowerId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFollowLink = /* GraphQL */ `
  subscription OnUpdateFollowLink {
    onUpdateFollowLink {
      id
      following {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowingId
      follower {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowerId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFollowLink = /* GraphQL */ `
  subscription OnDeleteFollowLink {
    onDeleteFollowLink {
      id
      following {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowingId
      follower {
        id
        user_id
        username
        preferred_username
        email
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        postAdded
        posts {
          nextToken
        }
        profilePicLink
        awsIdentityId
        profilePic {
          bucket
          region
          key
        }
        profilePicIcon {
          bucket
          region
          key
        }
        profilePicAdded
        bio
        bioAdded
        followers {
          nextToken
        }
        following {
          nextToken
        }
        notifications {
          nextToken
        }
        notificationsCreated {
          nextToken
        }
        requests {
          nextToken
        }
        requestsCreated {
          nextToken
        }
        companions {
          nextToken
        }
        companionAdded
        createdAt
        updatedAt
      }
      followLinkFollowerId
      createdAt
      updatedAt
    }
  }
`;
