import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ErrorMessageRow(props) {
  return (
    <>
      <Row className={props.rowClass}>
        <Col className="text-center">
          <small className="text-danger font-italic">{props.message}</small>
        </Col>
      </Row>
    </>
  );
}

export default ErrorMessageRow;
